import { User_Role_Enum } from 'src/types/custom-generated'

export const actionEnum: { [key: string]: any } = {
    read: 'Read',
    write: "Write",
    delete: "Delete",
    publish: "Publish",
    update: "Update",
};

export const viewEnum: { [key: string]: any } = {
    pages: 'Page',
    products: 'Product',
    datasets: 'Dataset',
    navigation: 'Navigation',
    'content-plan': 'Plan',
    tasks: 'Task',
    assets: 'Asset',
    'data-structures': 'DataStructure',
    tags: 'Tags',
    analytics: 'Analytic',
    bulk_upload: 'Bulk Upload',
    head_injection: 'Head Injection',
    newsletter: "Newsletter" 
};

export const roles: { [key: string]: any } = {
    // [User_Role_Enum.SuperAdmin]: {
    //     PerSpace: 1,
    //     HaveMultipleSpace: true,
    //     AddUserToSpace: true,
    //     ManageUser: true,
    //     DeletedBy: [],
    //     MakeSpaceOwner: [User_Role_Enum.Admin],
    //     CanTurnAdmin: true,
    //     Action: ["All"],
    //     Billing: true,
    //     CreateSpace: true,
    // },
    [User_Role_Enum.SpaceOwner]: {
        PerSpace: 1,
        HaveMultipleSpace: true,
        AddUserToSpace: true,
        ManageUser: true,
        DeletedBy: [],
        MakeSpaceOwner: [User_Role_Enum.Admin],
        CanTurnAdmin: true,
        Action: ["All"],
        Billing: true,
        CreateSpace: true,
    },
    [User_Role_Enum.Admin]: {
        PerSpace: 10,
        ManageUser: true,
        HaveMultipleSpace: true,
        AddUserToSpace: true,
        DeletedBy: [User_Role_Enum.SpaceOwner],
        CanTurnSpaceOwner: false,
        Action: ["All"],
        Billing: false,
        CreateSpace: false,
    },
    [User_Role_Enum.Manager]: {
        PerSpace: 10,
        DeletedBy: [User_Role_Enum.Admin, User_Role_Enum.SpaceOwner],
        Action: [
            { [viewEnum.assets]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum['data-structures']]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.tags]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.tasks]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum['content-plan']]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.analytics]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.pages]: ["Read", "Write", "Update", "Delete", "Publish"] },
            { [viewEnum.products]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.navigation]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.datasets]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.bulk_upload]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.head_injection]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.newsletter]: ["Read", "Write", "Update", "Delete"] }
        ]
    },
    [User_Role_Enum.Editor]: {
        PerSpace: 10,
        DeletedBy: [User_Role_Enum.Admin, User_Role_Enum.SpaceOwner],
        Action: [
            { [viewEnum.assets]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum['data-structures']]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.tags]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.tasks]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum['content-plan']]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.pages]: ["Read", "Write", "Update", "Delete", "Publish"] },
            { [viewEnum.products]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.navigation]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.datasets]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.bulk_upload]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.head_injection]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.newsletter]: ["Read", "Write", "Update", "Delete"] }
        ]
    },
    [User_Role_Enum.Author]: {
        PerSpace: 10,
        DeletedBy: [User_Role_Enum.Admin, User_Role_Enum.SpaceOwner, /* User_Role_Enum.SuperAdmin */],
        Action: [
            { [viewEnum.assets]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.tasks]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum['content-plan']]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.pages]: ["Read", "Write", "Update", "Delete", "Publish"] },
            { [viewEnum.products]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.datasets]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.newsletter]: ["Read", "Write", "Update", "Delete"] }
        ]
    },
    [User_Role_Enum.Contractor]: {
        PerSpace: 10,
        DeletedBy: [User_Role_Enum.Admin, User_Role_Enum.SpaceOwner, /* User_Role_Enum.SuperAdmin */],
        Action: [
            { [viewEnum.tasks]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum['content-plan']]: ["Read", "Write", "Update", "Delete"] },
            { [viewEnum.pages]: ["Read", "Write", "Update", "Delete", "Publish"] },
        ]
    },
};

export const spaceOwnerAlreadyExists = (
    ownerRole: string,
    userRole: string,
    ownerId: string,
    userId: string
) => {
    return (
        (ownerRole === User_Role_Enum.SpaceOwner &&
        userRole === User_Role_Enum.SpaceOwner) && ownerId !== userId
    );
};

export const canAccess = (
    ownerRole: string,
    action: string,
    view: string,
    createdBy?: boolean
) => {
    const permission = roles[ownerRole].Action.find((item: any) => item[view] || item === "All");
    if (!permission) return false;
    if (permission === "All") return true;
    if (view === viewEnum.pages && (action === actionEnum.read || action === actionEnum.write)) {
        const checkCreatedBy = ownerRole === User_Role_Enum.Contractor ? createdBy : true;
        return (permission[view].includes(action) && checkCreatedBy);
    }
    return permission[view].includes(action);
};

export const canAccessSidebar = (
    ownerRole: string,
    view: string,
) => {
    const permission = roles[ownerRole].Action.find((item: any) => item[view] || item === "All");
    if (!permission) return false;
    return true;
};
