// action - state management
import { createSlice } from "@reduxjs/toolkit";
import { required } from "src/utils/validation";
import { cloneDeep } from "src/utils/helper";
// ==============================|| ACCOUNT REDUCER ||============================== //

const customerMainInput = {
  name: "",
  slug: "",
  navigation_name: "",
  tags: "",
  meta_description: "",
  head_injection: "",
};
const initialState = {
  loading: false,
  blockId: "",
  customerMainInput: cloneDeep(customerMainInput),
  customerMainInputError: {},
  customers: {
    rows: [],
    order: "asc",
    orderBy: "",
    limit: 50,
    search: "",
    count: 0,
    offset: 0,
    is_deleted: false,
  },
  customerToVerify: null,
};

const ReducerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload || false;
    },
    getBlockOptions(state, action) {
      console.log("selected block : " + action.payload);
      state.blockId = action.payload || "";
    },
    setCustomerMainInput(state: any, action) {
      console.log("options action.payload : " + action.payload);
      state.customerMainInput[action.payload.key] = action.payload.value || "";
      if (action.payload.required) {
        state.customerMainInputError[action.payload.key] = required(
          action.payload.value
        );
      }
    },
    setCustomerMainError(state, action) {
      state.customerMainInputError = action.payload;
    },
    setCustomerMain(state: any, action) {
      for (let key in action.payload) {
        if (key !== "__typename") {
          state.customerMainInput[key] = action.payload[key];
        }
      }
    },
    resetCustomerMain(state, action) {
      state.customerMainInput = cloneDeep(customerMainInput);
    },
    setCustomers(state, action) {
      if (action.payload.user) {
        state.customers.rows = action.payload.user;
      }
      if (
        action.payload.user_aggregate &&
        action.payload.user_aggregate.aggregate
      ) {
        state.customers.count =
          action.payload.user_aggregate.aggregate.totalCount;
      }
      if (
        action.payload.offset !== undefined &&
        action.payload.offset !== null
      ) {
        state.customers.offset = action.payload.offset;
      }
      if (action.payload.limit !== undefined && action.payload.limit !== null) {
        state.customers.limit = action.payload.limit;
      }
      if (
        action.payload.search !== undefined &&
        action.payload.search !== null
      ) {
        state.customers.search = action.payload.search;
      }
    },
    verifyCustomer(state, action) {
      state.customerToVerify = action.payload;
    },
  },
});

export const {
  getBlockOptions,
  setCustomerMainInput,
  setCustomerMain,
  setCustomerMainError,
  resetCustomerMain,
  setLoading,
  setCustomers,
  verifyCustomer,
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
