// @ts-nocheck
import React from 'react'
import Lottie from 'react-lottie'
import { useDispatch, useSelector } from 'react-redux'
// @ts-ignore
import { Outlet } from 'react-router-dom'

// material-ui
import { styled, useTheme, Theme } from '@mui/material/styles'
import loadingAnimation from 'src/assets/lottie/data-loader.json'
import { AppBar, Box, CssBaseline, useMediaQuery, Grid, Button, Typography, Divider } from '@mui/material'

// project imports
import Breadcrumbs from 'src/ui-component/extended/Breadcrumbs'
import Header from './Header'
import Sidebar from './Sidebar'
import navigation from 'src/menu-items'
import { drawerWidth } from 'src/store/constant'
import { SET_MENU } from 'src/store/actions'
import { DefaultRootStateProps } from 'src/types'
import { useEffect } from 'react'
import useSpace from 'src/hooks/useSpace'

// assets
import { IconChevronRight } from '@tabler/icons'
import logo from 'src/assets/images/hyzebra.png'
import SpaceSelection from 'src/layout/MainLayout/Header/SpaceSelection'
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineTwoTone'
import SupportIcon from '@mui/icons-material/SupportTwoTone'
// import ProfileIcon from '@mui/icons-material/Person2TwoTone'
import { SpaceIcon } from 'src/assets/icons'
import { useLoading } from 'src/contexts/LoadingContext'

interface MainStyleProps {
    theme: Theme
    open: boolean
}

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        // [theme.breakpoints.up('md')]: {
        //     marginLeft: -(drawerWidth - 20),
        //     width: `calc(100% - ${drawerWidth}px)`
        // },
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: '20px',
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     padding: '16px'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: '10px',
        //     width: `calc(100% - ${drawerWidth}px)`,
        //     padding: '16px',
        //     marginRight: '10px'
        // }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        // width: `calc(100% - ${drawerWidth}px)`,
        // [theme.breakpoints.down('md')]: {
        //     marginLeft: '20px'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft: '10px'
        // }
    })
}))

// ==============================|| MAIN LAYOUT ||============================== //

function Footer() {
    const footerStyle = {
        color: 'black',
        padding: '20px 40px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }

    const sectionStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
    }

    const titleStyle = {
        color: 'black',
        marginRight: '10px'
    }

    const linkStyle = {
        color: 'gray',
        textDecoration: 'none',
        marginRight: '10px',
    }

    return (
        <footer style={footerStyle}>

            <div style={sectionStyle}>
                <h4 style={titleStyle}>What we do:</h4>
                <a href='#' style={linkStyle}>Plans</a>
                <a href='#' style={linkStyle}>Overview</a>
                <a href='#' style={linkStyle}>Features</a>
                <a href='#' style={linkStyle}>Network</a>
                <a href='#' style={linkStyle}>Apps</a>
            </div>

            <div style={sectionStyle}>
                <h4 style={titleStyle}>Resources:</h4>
                <a href='#' style={linkStyle}>Documentation</a>
                <a href='#' style={linkStyle}>Blog</a>
                <a href='#' style={linkStyle}>Case studies</a>
                <a href='#' style={linkStyle}>Partners</a>
                <a href='#' style={linkStyle}>Customers</a>
                <a href='#' style={linkStyle}>API</a>
            </div>

            <div style={sectionStyle}>
                <h4 style={titleStyle}>Support:</h4>
                <a href='#' style={linkStyle}>Knowledgebase</a>
                <a href='#' style={linkStyle}>Community</a>
                <a href='#' style={linkStyle}>System status</a>
                <a href='#' style={linkStyle}>Videos</a>
                <a href='#' style={linkStyle}>Trust & Safety</a>
            </div>

            <div style={sectionStyle}>
                <h4 style={titleStyle}>About us:</h4>
                <a href='#' style={linkStyle}>Our team</a>
                <a href='#' style={linkStyle}>Careers</a>
                <a href='#' style={linkStyle}>Press</a>
                <a href='#' style={linkStyle}>Terms of Use</a>
                <a href='#' style={linkStyle}>Subscription Agreement</a>
                <a href='#' style={linkStyle}>Privacy Policy</a>
                <a href='#' style={linkStyle}>Privacy Choices</a>
            </div>
        </footer>
    )
}

const MainLayout = () => {
    const spacing = 1.5
    const theme = useTheme()
    const [space] = useSpace()
    const { isLoading } = useLoading()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'))

    useEffect(() => {
        document.title = `${space?.name ? `${space.name} -` : ''} hyzebra`
    }, [space])

    // Handle left drawer
    const leftDrawerOpened: boolean = useSelector((state: DefaultRootStateProps) => state.customization.opened)
    const dispatch = useDispatch()
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
    }

    const defaultOptionsLoader = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    React.useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd])

    return (
        <>
            <CssBaseline />

            {/* header */}
            <Grid
                container
                direction='row'
                sx={{ height: '100vh', width: '100vw', margin: 0, padding: spacing, paddingBottom: 0, bgcolor: '#fafafa' }}
            >
                {/* This Grid item will represent the header and have a fixed height */}
                <Grid container component='header'
                    sx={{
                        margin: 0,
                        padding: 0,
                        height: '60px',
                        paddingX: spacing,
                        bgcolor: '#ffffff',
                        borderRadius: '6px',
                        alignItems: 'center',
                        marginBottom: spacing,
                        border: '1px solid #eaeaea',
                        justifyContent: 'space-between',
                    }}
                    xs={12}
                >

                    {/* Left Space */}
                    <Grid item xs>
                        <SpaceSelection />
                    </Grid>

                    {/* Logo in the Middle */}
                    <Grid item>
                        <a href='/' style={{ height: '26px' }}>
                            <img src={logo} style={{ height: '26px' }} alt='Logo' />
                        </a>
                    </Grid>

                    {/* Multiple Items on the Right */}
                    <Grid item xs>
                        <Grid xs container spacing={3} justifyContent='flex-end'>
                            <Grid item>
                                <Button
                                    size='small'
                                    startIcon={<SupportIcon sx={{ fontSize: 1 }} />}
                                    onClick={() => 
                                        window.open("https://hyzebra.com/support")
                                    }
                                >
                                    Support
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    size='small'
                                    startIcon={<SpaceIcon stroke={1.2} width={20} height={20} />}
                                    href='/space/list'
                                >
                                    Spaces
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* This Grid item will take up the rest of the vertical space */}
                <Grid item container xs={12} sx={{ flexGrow: 1, height: `calc(100vh - 60px - ${1 * spacing}rem)` }}>
                    {/* drawer */}
                    <Grid item sx={{ height: '100%', paddingBottom: spacing }}>
                        <Box sx={{
                            height: '100%',
                            overflowY: 'auto',
                            border: '1px solid #eaeaea',
                            bgcolor: '#ffffff',
                            borderRadius: '6px',
                            // marginRight: spacing
                        }}>
                            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
                        </Box>
                    </Grid>
                    {/* <Grid item>
                        <Divider orientation='vertical' />
                    </Grid> */}
                    <Grid item container xs
                        sx={{
                            overflowY: 'auto',
                            marginLeft: spacing,
                            // flexGrow: 1,
                            height: '100%',
                            paddingBottom: spacing
                            // border: '1px solid #eaeaea',
                            // bgcolor: '#ffffff',
                            // borderRadius: '6px'
                        }}
                    >

                        {/* data loading animation */}
                        {isLoading && <Grid item xs={12} sx={{
                            zIndex: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: `calc(100vh - 60px - ${2 * spacing}rem - 270px)`
                        }}>
                            <Lottie options={defaultOptionsLoader} height={160} width={160} />
                        </Grid>}
                        <Grid item xs={12} sx={{
                            // marginLeft: spacing,
                            pointerEvents: isLoading ? 'none' : 'auto',
                            visibility: isLoading ? 'hidden' : 'visible',
                            height: isLoading ? 0 : 'auto',  // this ensures the Main doesn't take space
                            overflow: 'hidden'  // this hides content if it overflows the height of 0
                        }}>
                            <Main theme={theme} open={leftDrawerOpened} sx={{ margin: 0, padding: 0 }}>
                                <Outlet />
                            </Main>
                        </Grid>

                        {/* <Grid item xs={12} sx={{ marginTop: spacing }}>
                            <Divider sx={{ color: 'red' }} />
                        </Grid> */}
                        <Grid item xs={12} alignSelf='flex-end' sx={{
                            marginTop: spacing,
                            height: '270px',
                            // marginLeft: spacing,
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #eaeaea',
                            bgcolor: '#ffffff',
                            borderRadius: '6px'
                        }}>
                            <Footer />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default MainLayout
