// action - state management
import { createSlice } from "@reduxjs/toolkit";
// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    loading: false,
    defaultPageFooter: null,
    defaultNewsletterFooter: null,
    isTypeModalOpen: false,  
    footers: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 50,
        search: "",
        count: 0,
        offset: 0,
        is_deleted: false,
    },
    footerToDelete: null,
};

const ReducerSlice = createSlice({
    name: "footer",
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setTypeModal(state, action) {
            state.isTypeModalOpen = action.payload || false;
        },
        setDefaultPageFooter(state, action) {
            state.defaultPageFooter = action.payload || null;
        },
        setDefaultNewsletterFooter(state, action) {
            state.defaultNewsletterFooter = action.payload || null;
        },
        setFooters(state, action) {
            if (action.payload.footer) {
                state.footers.rows = action.payload.footer;
            }
            if (
                action.payload.footer_aggregate &&
                action.payload.footer_aggregate.aggregate
            ) {
                state.footers.count =
                    action.payload.footer_aggregate.aggregate.totalCount;
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.footers.offset = action.payload.offset;
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.footers.limit = action.payload.limit;
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.footers.search = action.payload.search;
            }
            if (
                action.payload.is_deleted !== undefined &&
                action.payload.is_deleted !== null
            ) {
                state.footers.is_deleted = action.payload.is_deleted;
            }
        },
        deleteFooter(state, action) {
            state.footerToDelete = action.payload;
        }
    }
});

export const {
    setLoading,
    setFooters,
    deleteFooter,
    setTypeModal,
    setDefaultPageFooter,
    setDefaultNewsletterFooter
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
