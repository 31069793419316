import { useTheme } from '@mui/material/styles'
import { Box, Drawer, useMediaQuery, Button, Grid } from '@mui/material'
// import PerfectScrollbar from 'react-perfect-scrollbar'
import { MobileView, BrowserView } from 'react-device-detect'
import MenuList from './MenuList'
// import LogoSection from '../LogoSection'
import { CONFIG, drawerWidth } from 'src/store/constant'
import LogoutIcon from '@mui/icons-material/ExitToApp'
import useAuth from 'src/hooks/useAuth'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import ArrowBackIcon from '@mui/icons-material/KeyboardBackspace';
import { styled } from '@mui/material/styles'
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import useConfig from 'src/hooks/useConfig'
import { useNavigate } from 'react-router-dom'
import useSpace from 'src/hooks/useSpace'

export interface SidebarProps {
    drawerOpen?: boolean
    drawerToggle?: () => void
    window?: Window
}

const Sidebar = ({ drawerOpen, drawerToggle, window }: SidebarProps) => {
    const theme = useTheme()
    const navigate = useNavigate()
    const { logout } = useAuth()
    const [space] = useSpace();
    const { config, setConfig }: any = useConfig()
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

    const handleLogout = async () => {
        try {
            await logout()
        } catch (err) {
            console.error(err)
        }
    }

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => <MuiTooltip {...props} classes={{ popper: className }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                fontSize: 11,
                boxShadow: theme.shadows[1],
                color: 'rgba(0, 0, 0, 0.87)',
                backgroundColor: theme.palette.common.white
            }
        })
    )

    const drawer = (
        <>
            {/* <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box> */}
            {/* <PerfectScrollbar
                    component='div'
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 79px)',
                        
                        backgroundColor: '#fbfbfb'
                    }}
                >  */}
            <BrowserView style={{ height: '100%' }}>
                <Grid container direction={'row'} style={{ height: '100%' }}>
                    <Grid item xs={12} sx={{ position: 'relative', bgcolor: '#ffffff' }}>
                        <Box sx={{ position: 'sticky', top: 0, left: 0, bgcolor: '#ffffff', zIndex: 1 }}>
                            {config === 'Active' && space?.id ? <LightTooltip placement='right' title={drawerOpen ? '' : 'Back to Main'}>
                                <Button
                                    sx={{
                                        width: '100%',
                                        height: '50px',
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderBottom: '1px solid #eaeaea'
                                    }}
                                    disableRipple
                                    color='inherit'
                                    onClick={() => {
                                        window?.localStorage.removeItem(CONFIG);
                                        setConfig(null);
                                        navigate('/page/list')
                                        // Navigate()
                                    }}
                                    startIcon={<ArrowBackIcon sx={{ fontSize: '0.8rem' }} />}
                                >
                                    {drawerOpen ? 'Back to Main' : ''}
                                </Button>
                            </LightTooltip> : <></>}
                        </Box>
                        <Box sx={{ bgcolor: '#ffffff', padding: 1.5, }}>
                            <MenuList />
                        </Box>
                    </Grid>
                    <Grid item container alignSelf='flex-end' xs sx={{ position: 'sticky', bottom: 0, left: 0, bgcolor: '#ffffff' }}>
                        <Grid item xs={12}>
                            <Button
                                sx={{
                                    height: '50px',
                                    width: '100%',
                                    paddingLeft: drawerOpen ? 0 : '18px'
                                    // display: 'flex',
                                    // borderRadius: '4px',
                                    // alignItems: 'center',
                                    // justifyContent: 'start',
                                    // padding: `6px 12px 6px 12px`
                                }}
                                color='inherit'
                                onClick={handleLogout}
                                startIcon={<LogoutIcon sx={{ fontSize: '0.8rem' }} />}>
                                {drawerOpen ? 'Logout' : ''}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <LightTooltip placement='right' title={drawerOpen ? '' : 'Expand Sidebar'}>
                                <Button
                                    sx={{
                                        width: '100%',
                                        borderRadius: 0,
                                        height: '50px',
                                        borderTop: '1px solid #eaeaea',
                                        paddingLeft: drawerOpen ? 0 : '18px'
                                    }}
                                    disableRipple
                                    color='inherit'
                                    onClick={drawerToggle}
                                    startIcon={drawerOpen ? <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '0.8rem' }} /> : <KeyboardDoubleArrowRightIcon sx={{ fontSize: '0.8rem' }} />}
                                >
                                    {drawerOpen ? 'Collapse Sidebar' : ''}
                                </Button>
                            </LightTooltip>
                        </Grid>
                    </Grid>
                    {/* <Grid container item direction={'row'} alignSelf='flex-end'> */}

                    {/* <Divider variant='fullWidth' sx={{ width: '100%' }} />
                        <Grid item xs={12}>
                            <Button
                                sx={{
                                    width: '100%',
                                    borderRadius: 0,
                                    height: '50px',
                                    backgroundColor: '#f5f5f5'
                                    // display: 'flex',
                                    // alignItems: 'center',
                                    // justifyContent: 'start',
                                    // padding: `6px 12px 6px 12px`
                                }}
                                disableRipple
                                color='inherit'
                                onClick={drawerToggle}
                                startIcon={drawerOpen ? <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '0.8rem' }} /> : <KeyboardDoubleArrowRightIcon sx={{ fontSize: '0.8rem' }} />}
                            >
                                {drawerOpen ? 'Collapse Sidebar' : 'Expand Sidebar'}
                            </Button>
                        </Grid> */}
                    {/* </Grid> */}
                </Grid>
                {/* </PerfectScrollbar> */}
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </>
    )

    const container = window !== undefined ? () => window.document.body : undefined

    return (
        <Box component='nav' sx={{
            // backgroundColor: 'red',
            // borderTopRightRadius: '10px',
            // borderBottomRightRadius: '10px',
            height: '100%'
            // paddingLeft: 0
        }}>
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor='left'
                open={true}
                // onClose={drawerToggle}
                sx={{
                    height: '100%',
                    '& .MuiDrawer-paper': {
                        width: drawerOpen ? drawerWidth : 65,
                        transition: 'width 0.2s ease-in-out',
                        height: '100%',
                        // background: '#ffffff',
                        position: 'unset',
                        borderRight: 'none',
                    }
                }}
                // sx={{
                //     '& .MuiDrawer-paper': {
                //         width: drawerWidth,
                //         background: '#ffffff',
                //         color: theme.palette.text.primary,
                //         borderRight: 'none',
                //         [theme.breakpoints.up('md')]: {
                //             top: '79px'
                //         }
                //     }
                // }}
                ModalProps={{ keepMounted: true }}
            >
                {drawer}
            </Drawer>
        </Box>
    )
}

export default Sidebar
