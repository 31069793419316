import { createSlice } from "@reduxjs/toolkit";
// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState: any = {
    loading: false,
    data: {
        list: [],
        filters: {
            order: "asc",
            orderBy: "",
            limit: 50,
            search: "",
            count: 0,
            offset: 0,
            is_deleted: false,
        },
        input: {
            name: "",
        },
        updateSpace: null,
    },
    error: {},
    spaceStatus: [],
    spaceToDelete: null,
    fonts: []
}

const ReducerSlice = createSlice({
    name: 'space',
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setError(state, action) {
            state.error = action.payload || null;
        },
        setSpaceList(state, action) {
            state.data.list = [...action.payload];
        },
        setSpaceFilters(state, action) {
            state.data.filters = { ...state.data.filters, ...action.payload };
        },
        setSpaceInput(state, action) {
            state.data.input[action.payload.key] = action.payload.value;
        },
        setSpaceUpdate(state, action) {
            
            let newObject = action.payload.key.split(".");

            if(newObject.length == 3)
            {
                state.data.updateSpace[newObject[0]] = state.data.updateSpace[newObject[0]] || {};
                state.data.updateSpace[newObject[0]][newObject[1]] = state.data.updateSpace[newObject[0]][newObject[1]] || {};
                state.data.updateSpace[newObject[0]][newObject[1]][newObject[2]] =  action.payload.value;
            }   
            else
            {
                state.data.updateSpace[action.payload.key] = action.payload.value;
            } 
        },
        setSpaceStatus(state, action) {
            state.spaceStatus = [...action.payload];
        }
    }
});

export const {
    setLoading,
    setError,
    setSpaceList,
    setSpaceFilters,
    setSpaceInput,
    setSpaceUpdate,
    setSpaceStatus,
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
