// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'src/menu-items';
import { useEffect, useState } from 'react';
import { NavItemType } from 'src/types';
import useSpace from 'src/hooks/useSpace';
import { canAccessSidebar, viewEnum } from 'src/views/users/roles';
import { cloneDeep } from "src/utils/helper";
import useAuth from 'src/hooks/useAuth';
import { User_Role_Enum } from 'src/types/custom-generated';
import useConfig from 'src/hooks/useConfig'

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [space] = useSpace()
    const { config }: any = useConfig();
    const { user } = useAuth();
    const [navItems, setNavItems] = useState<any>();

    const getNavItems = (items: NavItemType[]) => items.map((item: NavItemType, idx: number) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} index={idx} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    useEffect(() => {
        // if(!space?.id) window.localStorage.removeItem(CONFIG);
        let items = getNavItems(space?.id && config === 'Active' ? menuItem.config : space?.id ? menuItem.space : menuItem.admin)
        
        let filteredItems = cloneDeep(items);
        if (space) {
            filteredItems = filteredItems.filter((re: any) => {
                if (canAccessSidebar(space.user_role, viewEnum[re.key])) return true;
                const children = re.props.item.children;
                const child = children.filter((item: any) => canAccessSidebar(space.user_role, viewEnum[item.id]))
                re.props.item.children = child;
                if (!child.length) return false;
                return true;
            })
        } else {
            if (!user?.personalSpaces?.length) {
                filteredItems = filteredItems.filter((re: any) => {
                    const children = re.props.item.children;
                    const child = children.filter((item: any) => item.id !== 'users')
                    re.props.item.children = child;
                    return true;
                })
            }
            if (!['w.kanewski@gmail.com', 'mehzabeen.choudhari@sourcework.in', 'tirthpatel5885@gmail.com'].includes(user?.email)) {
                filteredItems = filteredItems.filter((re: any) => {
                    // console.log(re.props.item.id);
                    // const children = re.props.item.children;
                    // const child = children.filter((item: any) => item.id !== 'blocks')
                    // re.props.item.children = child;
                    return re.props.item.id !== "superadmin";
                })
            }
            const ifSpaceOwners = user?.personalSpaces?.filter(({ user_role }: { user_role: string }) => user_role === User_Role_Enum.SpaceOwner);
            if (!ifSpaceOwners?.length) {
                filteredItems = filteredItems.filter((re: any) => {
                    const children = re.props.item.children;
                    const child = children.filter((item: any) => item.id !== 'exclusive-blocks')
                    re.props.item.children = child;
                    return true;
                })
            }
        }
        setNavItems([...filteredItems]);
    }, [space, config])

    return <>{navItems}</>;
};

export default MenuList;
