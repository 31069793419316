// action - state management
import { createSlice } from "@reduxjs/toolkit";

// ==============================|| ACCOUNT REDUCER ||============================== //


const initialState: any = {
    loading: false,
    forms: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 50,
        search: "",
        count: 0,
        offset: 0,
    },
    record: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 50,
        search: "",
        select: '',
        count: 0,
        offset: 0,
    },
    fields: {},
    formToDelete: null,
};

const ReducerSlice = createSlice({
    name: "form",
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setForms(state, action) {
            if (action.payload.form) {
                state.forms.rows = action.payload.form.map((x: any, index: number) => {

                    let y = { ...x };
                    if (index > 0 && action.payload.form[index - 1].slug === x.slug) {

                        y.hasDuplicate = true;
                    }
                    return y;
                });
            }
            if (
                action.payload.form_aggregate &&
                action.payload.form_aggregate.aggregate
            ) {
                state.forms.count = action.payload.form_aggregate.aggregate.totalCount;
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.forms.offset = action.payload.offset;
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.forms.limit = action.payload.limit;
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.forms.search = action.payload.search;
            }
            if (
                action.payload.is_deleted !== undefined &&
                action.payload.is_deleted !== null
            ) {
                state.forms.is_deleted = action.payload.is_deleted;
            }
        },
        setFormToDelete(state, action) {
            state.formToDelete = action.payload;
        },
        deleteForm(state) {
            state.forms.rows = state.forms.rows.filter(
                (form: any) => form.id !== state.formToDelete.id
            );
        },
        setFields(state, action) {
            const { id, fields } = action.payload;
            state.fields[id] = fields;
        },
        clearDeletedBlockIds(state) {
            state.blockIdsToDelete = [];
        },
        setRecords(state, action) {
            if (action.payload.record) {
                state.record.rows = action.payload.record;
              }
            if (
                action.payload.record_aggregate &&
                action.payload.record_aggregate.aggregate
            ) {
                state.record.count = action.payload.record_aggregate.aggregate.totalCount;
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.record.offset = action.payload.offset;
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.record.limit = action.payload.limit;
            }
            if (
                action.payload.is_deleted !== undefined &&
                action.payload.is_deleted !== null
            ) {
                state.record.is_deleted = action.payload.is_deleted;
            }
            if (
                action.payload.select !== undefined &&
                action.payload.select !== null
            ) {
                state.record.select = action.payload.select;
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.record.search = action.payload.search;
            }
        },
    },
});

export const {
    setLoading,
    setForms,
    setFormToDelete,
    deleteForm,
    setFields,
    clearDeletedBlockIds,
    setRecords
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
