import React, { createContext, useContext, useState, useEffect, useRef, ReactNode } from 'react'

type LoadingContextType = {
    isLoading: boolean
    setIsLoading: (value: boolean) => void
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined)

export const useLoading = (): LoadingContextType => {
    const context = useContext(LoadingContext)
    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider')
    }
    return context
}

type LoadingProviderProps = {
    children: ReactNode
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
    const timerDuration = 1000
    const [isLoading, _setIsLoading] = useState(false)
    const requestedToStopRef = useRef(false)
    const timerStartedRef = useRef(false)
    const timerRef = useRef<NodeJS.Timeout | null>(null)

    const setIsLoading = (value: boolean) => {
        if (value) {
            if (timerStartedRef.current) return // Timer already started, disregard subsequent calls

            _setIsLoading(true)
            timerStartedRef.current = true

            timerRef.current = setTimeout(() => {
                timerStartedRef.current = false
                if (requestedToStopRef.current || !isLoading) {
                    _setIsLoading(false)
                    requestedToStopRef.current = false
                }
            }, timerDuration)
        } else {
            if (timerStartedRef.current) {
                // Timer is still running, mark as requested to stop
                requestedToStopRef.current = true
            } else {
                _setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
        }
    }, [])

    return (
        <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
            {children}
        </LoadingContext.Provider>
    )
}
