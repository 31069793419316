// action - state management
import { createSlice } from "@reduxjs/toolkit";
import { required } from "src/utils/validation";
import { cloneDeep } from "src/utils/helper";
// ==============================|| ACCOUNT REDUCER ||============================== //

const tagMainInput = {
  name: "",
  type: ""
  };
const initialState = {
  loading: false,
  blockId: "",
  tagMainInput: cloneDeep(tagMainInput),
  tagMainInputError: {},
  tags: {
    rows: [],
    order: "asc",
    orderBy: "",
    limit: 50,
    search: "",
    count: 0,
    offset: 0,
    is_deleted: false,
  },
  tagTypes : [],
  tagToDelete: null,
};

const ReducerSlice = createSlice({
  name: "tag",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload || false;
    },
    getBlockOptions(state, action) {
      console.log("selected block : " + action.payload);
      state.blockId = action.payload || "";
    },
    setTagMainInput(state: any, action) {
      
      console.log("options action.payload : " + action.payload);
      state.tagMainInput[action.payload.key] = action.payload.value || "";
      if (action.payload.required) {
        state.tagMainInputError[action.payload.key] = required(
          action.payload.value
        );
      }
    },
    setTagMainError(state, action) {
      state.tagMainInputError = action.payload;
    },
    setTagMain(state: any, action) {
      for (let key in action.payload) {
        if (key !== "__typename" && key !== "relations") {
          state.tagMainInput[key] = action.payload[key];
        }
      }
    },
    resetTagMain(state) {
      state.tagMainInput = cloneDeep(tagMainInput);
    },
    setTags(state, action) {
      if (action.payload.tag) {
        state.tags.rows = action.payload.tag;
      }
      if (
        action.payload.tag_aggregate &&
        action.payload.tag_aggregate.aggregate
      ) {
        state.tags.count =
          action.payload.tag_aggregate.aggregate.totalCount;
      }
      if (
        action.payload.offset !== undefined &&
        action.payload.offset !== null
      ) {
        state.tags.offset = action.payload.offset;
      }
      if (action.payload.limit !== undefined && action.payload.limit !== null) {
        state.tags.limit = action.payload.limit;
      }
      if (
        action.payload.search !== undefined &&
        action.payload.search !== null
      ) {
        state.tags.search = action.payload.search;
      }
      if (
        action.payload.is_deleted !== undefined &&
        action.payload.is_deleted !== null
      ) {
        state.tags.is_deleted = action.payload.is_deleted;
      }
    },
    deleteTag(state, action) {
      state.tagToDelete = action.payload;
    },
    setTagTypes(state,action)
    {
       state.tagTypes  = action.payload; 
    }
  },
});

export const {
  getBlockOptions,
  setTagMainInput,
  setTagMain,
  setTagMainError,
  resetTagMain,
  setLoading,
  setTags,
  deleteTag,
  setTagTypes
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
