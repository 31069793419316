import { createSlice } from '@reduxjs/toolkit'
import { required } from 'src/utils/validation'

const initialState = {
    loading: false,
    assetMainInput: null,
    assetMainInputError: {},
    assets: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 50,
        search: "",
        tags: [],
        count: 0,
        offset: 0,
        is_deleted: false,
    },
    assetTypes: [],
    assetToDelete: null
}

const ReducerSlice = createSlice({
    name: 'asset',
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setAssetMainInput(state: any, action) {
            console.log("options action.payload : " + action.payload)
            state.assetMainInput[action.payload.key] = action.payload.value || ''
            if (action.payload.required) state.assetMainInputError[action.payload.key] = required(action.payload.value)
        },
        setAssetMainError(state, action) {
            state.assetMainInputError = action.payload
        },
        setAssetMain(state: any, action) {
            state.assetMainInput = action.payload
        },
        resetAssetMain(state) {
            state.assetMainInput = null
        },
        setAssets(state, action) {
            if (action.payload.assets) state.assets.rows = action.payload.assets
            if (
                action.payload.asset_aggregate &&
                action.payload.asset_aggregate.aggregate
            ) {
                state.assets.count = action.payload.asset_aggregate.aggregate.totalCount
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.assets.offset = action.payload.offset
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.assets.limit = action.payload.limit
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.assets.search = action.payload.search
            }
            if (
                action.payload.tags !== undefined &&
                action.payload.tags !== null
            ) {
                state.assets.tags = action.payload.tags
            }
            if (
                action.payload.is_deleted !== undefined &&
                action.payload.is_deleted !== null
            ) {
                state.assets.is_deleted = action.payload.is_deleted
            }
        },
        setAssetToDelete(state, action) {
            const { index } = action.payload
            state.assetToDelete = index
        },
        deleteAsset(state) {
            if (state.assetToDelete || state.assetToDelete === 0) state.assets.rows.splice(state.assetToDelete, 1)
        },
        setAssetTypes(state, action) {
            state.assetTypes = action.payload
        }
    }
})

export const {
    setAssetMainInput,
    setAssetMain,
    setAssetMainError,
    resetAssetMain,
    setAssets,
    deleteAsset,
    setAssetTypes,
    setLoading,
    setAssetToDelete
} = ReducerSlice.actions
export default ReducerSlice.reducer
