// action - state management
import { createSlice } from "@reduxjs/toolkit";
// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState: any = {
  loading: false,
  spaceList: [],
  users: {
    rows: [],
    space: "",
    order: "asc",
    orderBy: "",
    limit: 50,
    search: "",
    count: 0,
    offset: 0,
    is_deleted: false,
  },
  userToDelete: null,
};

const ReducerSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload || false;
    },
    setSpaceList(state, action) {
        state.spaceList = [...action.payload];
    },
    setUsers(state, action) {
      if (action.payload.users) {
        state.users.rows = action.payload.users;
      }
      if (action.payload.space) {
        state.users.space = action.payload.space;
      }
      if (
        action.payload.user_aggregate &&
        action.payload.user_aggregate.aggregate
      ) {
        state.users.count = action.payload.user_aggregate.aggregate.totalCount;
      }
      if (
        action.payload.offset !== undefined &&
        action.payload.offset !== null
      ) {
        state.users.offset = action.payload.offset;
      }
      if (action.payload.limit !== undefined && action.payload.limit !== null) {
        state.users.limit = action.payload.limit;
      }
      if (
        action.payload.search !== undefined &&
        action.payload.search !== null
      ) {
        state.users.search = action.payload.search;
      }
      if (
        action.payload.is_deleted !== undefined &&
        action.payload.is_deleted !== null
      ) {
        state.users.is_deleted = action.payload.is_deleted;
      }
    },
    setUserToDelete(state, action) {
      state.userToDelete = action.payload;
    },
    deleteUser(state) {
      state.users.rows = state.users.rows.filter(
        (user: any) => user.id !== state.userToDelete.id
      );
    }
  },
});

export const {
  setLoading,
  setUsers,
  setUserToDelete,
  deleteUser,
  setSpaceList,
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
