import { lazy } from 'react'
import AuthGuard from 'src/utils/route-guard/AuthGuard'
import MinimalLayout from 'src/layout/MinimalLayout'
import NavMotion from 'src/layout/NavMotion'
import Loadable from 'src/ui-component/Loadable'
import type { RouteObject } from 'react-router-dom'

// const Index = Loadable(lazy(() => import('src/views/index/Home')))
// const AuthLogin = Loadable(lazy(() => import('src/views/authentication/authentication3/Login3')))
// const AuthRegisterInfo = Loadable(lazy(() => import('src/views/authentication/authentication3/RegisterInfo')))
const SignUp = Loadable(lazy(() => import('src/views/authentication/authentication3/SignUp')))
const SignIn = Loadable(lazy(() => import('src/views/authentication/authentication3/SignIn')))

const LoginRoutes: RouteObject[] = [
    {
        path: '/',
        element: <SignUp />
    },
    {
        path: '/login',
        element: <SignIn />
    },
    {
        path: '/create-account',
        element: <SignUp />
    }
]

const AuthenticationRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <AuthGuard>
                <MinimalLayout />
            </AuthGuard>
        </NavMotion>
    ),
    children: LoginRoutes
}

export { LoginRoutes }
export default AuthenticationRoutes
