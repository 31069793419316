// action - state management
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "src/utils/helper";
// ==============================|| ACCOUNT REDUCER ||============================== //

const componentMainInput = {
    name: "",
    type: "",
    meta: {}
};

const initialState = {
    loading: false,
    componentMainInput: cloneDeep(componentMainInput),
    components: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 50,
        search: "",
        count: 0,
        offset: 0,
        is_deleted: false,
    },
};

const ReducerSlice = createSlice({
    name: "component",
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setComponents(state, action) {
            if (action.payload.component) {
                state.components.rows = action.payload.component;
            }
            if (
                action.payload.component_aggregate &&
                action.payload.component_aggregate.aggregate
            ) {
                state.components.count =
                    action.payload.component_aggregate.aggregate.totalCount;
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.components.offset = action.payload.offset;
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.components.limit = action.payload.limit;
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.components.search = action.payload.search;
            }
            if (
                action.payload.is_deleted !== undefined &&
                action.payload.is_deleted !== null
            ) {
                state.components.is_deleted = action.payload.is_deleted;
            }
        },
        setComponentMainInput(state: any, action) {
            state.componentMainInput[action.payload.key] = action.payload.value || "";
        },
        setComponentMetaInput(state: any, action) {
            state.componentMainInput.meta[action.payload.key] = action.payload.value || "";
        },
        resetComponentMain(state) {
            state.componentMainInput = cloneDeep(componentMainInput);
        },
        setComponentMain(state: any, action) {
            for (let key in action.payload) {
                if (key !== "__typename") {
                    state.componentMainInput[key] = action.payload[key];
                }
            }
        }
    }
});

export const {
    setLoading,
    setComponents,
    resetComponentMain,
    setComponentMainInput,
    setComponentMain,
    setComponentMetaInput
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
