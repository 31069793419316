import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { GenericCardProps, DefaultRootStateProps } from 'src/types';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

export interface NavGroupProps {
    item: {
        id?: string;
        type?: string;
        icon?: GenericCardProps['iconPrimary'];
        children?: NavGroupProps['item'][];
        title?: React.ReactNode | string;
        caption?: React.ReactNode | string;
        color?: 'primary' | 'secondary' | 'default' | undefined;
    };
    index: number
}

const NavGroup = ({ item, index }: NavGroupProps) => {
    const theme = useTheme();
    const drawerOpen: boolean = useSelector((state: DefaultRootStateProps) => state.customization.opened)

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                sx={{ padding: '0px 0px 10px 0px', borderTop: drawerOpen || index === 0 ? 'none' : '1px solid #eaeaea', paddingTop: index === 0 ? '0px' : '10px', display: !drawerOpen ? 'flex': 'inherit', flexDirection: !drawerOpen ? 'column' : 'inherit', alignItems: !drawerOpen ? 'center' : 'inherit' }}
                subheader={
                    item.title && drawerOpen && (
                        <Typography variant="caption" fontSize={'12px'} fontWeight={300} color={'#999999'} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {items}
            </List>

            {/* group divider */}
            {/* <Divider sx={{ mt: 0.25, mb: 1.25 }} /> */}
        </>
    );
};

export default NavGroup;
