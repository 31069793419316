// action - state management
import { createSlice } from "@reduxjs/toolkit";
// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    loading: false,
    defaultSidebar: null,
    sidebars: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 50,
        search: "",
        count: 0,
        offset: 0,
        is_deleted: false,
    },
    sidebarToDelete: null,
};

const ReducerSlice = createSlice({
    name: "sidebar",
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setDefaultSidebar(state, action) {
            state.defaultSidebar = action.payload || null;
        },
        setSidebars(state, action) {
            if (action.payload.sidebar) {
                state.sidebars.rows = action.payload.sidebar;
            }
            if (
                action.payload.sidebar_aggregate &&
                action.payload.sidebar_aggregate.aggregate
            ) {
                state.sidebars.count =
                    action.payload.sidebar_aggregate.aggregate.totalCount;
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.sidebars.offset = action.payload.offset;
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.sidebars.limit = action.payload.limit;
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.sidebars.search = action.payload.search;
            }
            if (
                action.payload.is_deleted !== undefined &&
                action.payload.is_deleted !== null
            ) {
                state.sidebars.is_deleted = action.payload.is_deleted;
            }
        },
        deleteSidebar(state, action) {
            state.sidebarToDelete = action.payload;
        }
    },
});

export const {
    setLoading,
    setSidebars,
    deleteSidebar,
    setDefaultSidebar
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
