import moment from 'moment'
import momentTimeZone from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep'

const dateFormat = 'MMM DD, YYYY'
const timeFormat = 'hh:mm A'

export { cloneDeep }

export function getFormattedDateTime(date: any, format?: any) {
    // let clientTimezoneOffsetValue = 330
    // let formatDate = format ? format : dateFormat + ' ' + timeFormat;
    // let newDate = '';
    // if (date) {
    //     date = new Date(date);
    //     // convert to msec, add local time zone offset, get UTC time in msec
    //     let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
    //     // create new Date object for different city, using supplied offset
    //     date = new Date(utc + (60000 * clientTimezoneOffsetValue));
    //     newDate = moment(date).format(formatDate);
    // }

    let formatDate = format ? format : `${dateFormat} ${timeFormat}`;
    let newDate = '';

    if (date) {
        let momentDate = momentTimeZone(date);
        newDate = momentDate.format(formatDate);
    }

    return newDate;
}
// export function getFormattedDateTimeWithTimezone(date: any,  format?: any,timezone: string = '',) {
//     let clientTimezoneOffsetValue = 330
//     let formatDate = format ? format : dateFormat + ' ' + timeFormat;
//     let newDate = '';
//     if (date) {
//         date = new Date(date);
//         // convert to msec, add local time zone offset, get UTC time in msec
//         let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
//         // create new Date object for different city, using supplied offset
//         date = timezone ? new Date(utc + (60000 * clientTimezoneOffsetValue)).toLocaleString("en-US", { timeZone: `${timezone}` }) : new Date(utc + (60000 * clientTimezoneOffsetValue));
//         newDate = moment(date).format(formatDate);
//     }
//     return newDate
// }

export function getFormattedDateTimeWithTimezone(date: any, format?: any, timezone: string = '') {
    let formatDate = format ? format : `${dateFormat} ${timeFormat}`;
    let newDate = '';

    if (date) {
        let momentDate = momentTimeZone(date);

        if (timezone) {
            momentDate = momentDate.tz(timezone);
        }

        newDate = momentDate.format(formatDate);
    }

    return newDate;
}


export function getFormattedDate(date: any, format?: any) {
    // let clientTimezoneOffsetValue = 0
    let formatDate = format ? format : dateFormat;
    let newDate = '';
    if (date) {
        date = new Date(date);
        // convert to msec, add local time zone offset, get UTC time in msec
        //   let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        // create new Date object for different city, using supplied offset
        //  date = new Date(utc + (60000 * clientTimezoneOffsetValue));
        newDate = moment(date).format(formatDate);
    }
    return newDate
}
export function getFormattedDateWithTimezone(date: any,format?: any, timezone: string = '') {
    // let clientTimezoneOffsetValue = 0
    let formatDate = format ? format : dateFormat;
    let newDate = '';
    if (date) {
        date = date = timezone ?new Date(date).toLocaleString("en-US", { timeZone: `${timezone}` }) : new Date(date);
        // convert to msec, add local time zone offset, get UTC time in msec
        //   let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        // create new Date object for different city, using supplied offset
        //  date = new Date(utc + (60000 * clientTimezoneOffsetValue));
        newDate = moment(date).format(formatDate);
    }
    return newDate
}


export function getLocalDate(date: any) {
    let client_timezone_offset_value = 0;
    let newDate = null;
    if (date) {
        date = new Date(date);

        // convert to msec , add local time zone offset get UTC time in msec
        let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        // create new Date object for different city , using supplied offset
        newDate = new Date(utc + (60000 * (client_timezone_offset_value)));
    }
    return newDate;
}


export function getLocalDateTime(date: any) {
    let clientTimezoneOffsetValue = 0
    let newDate;
    if (date) {
        date = new Date(date);
        // convert to msec, add local time zone offset, get UTC time in msec
        let utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        // create new Date object for different city, using supplied offset
        date = new Date(utc + (60000 * clientTimezoneOffsetValue));
        newDate = moment(date);
    }
    return newDate
}




export function addDays(date: Date, days: number) {

    date = new Date(date);

    date = new Date(date.setDate(date.getDate() + 30));

    return date
}

export function initOptions(
    startYear: number,
    duration: number,
    step: number = 1,
    exclusions: Array<number> = []
) {
    let years: any = [];
    for (
        let i = startYear;
        duration > 0 ? i <= startYear + duration : i >= startYear + duration;
        duration > 0 ? (i += step) : (i -= step)
    ) {
        if (!exclusions.includes(i)) years.push(i);
    }
    return years;
}

// @ts-ignore
export const unflatten = (obj, sep = '.') => Object.keys(obj).reduce((res, k) => ((k.split(sep).reduce((acc, e, i, keys) => acc[e] || (acc[e] = isNaN(Number(keys[i + 1])) ? keys.length - 1 === i ? obj[k] : {} : []), res)), res), {})

// @ts-ignore
export const flatten = (obj: any, roots = [], sep = '.') => Object.keys(obj).reduce((memo, prop) => Object.assign({}, memo, Object.prototype.toString.call(obj[prop]) === '[object Object]' ? flatten(obj[prop], roots.concat([prop]), sep) : { [roots.concat([prop]).join(sep)]: obj[prop] }), {})

export const splitArrayIntoFlattenObj = (obj: any) => {
    Object.keys(obj).forEach((key: any) => {
        if (Array.isArray(obj[key])) {
            obj[key] = flatten(obj[key])
            splitArrayIntoFlattenObj(obj[key])
        }
    })
    return obj
}

export const dateEqualizer = (start: Date | string, end: Date | string, intervals: number) => {
    const endTime = new Date(end).getTime()
    const startTime = new Date(start).getTime()
    const difference = endTime - startTime
    const length = difference / intervals
    return Array(intervals + 1).fill(null).map((_, idx) => new Date(startTime + idx * length))
}

export const getTimeAgo = (date: Date) => {
    const now = new Date();
    const diff = (now.getTime() - date.getTime()) / 1000; // get difference in seconds

    if (diff < 60) {
        return "Just now";
    } else if (diff < 3600) {
        const minutes = Math.floor(diff / 60);
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else if (diff < 86400) {
        const hours = Math.floor(diff / 3600);
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else {
        const days = Math.floor(diff / 86400);
        return `${days} day${days > 1 ? "s" : ""} ago`;
    }
}
