// action - state management
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "src/utils/helper";

const spaceTemplateMainInput = {
    name: "",
    space: ""
};

const initialState: any = {
    loading: false,
    spaceTemplateMainInput: cloneDeep(spaceTemplateMainInput),
    spaceTemplateMainInputError: {},
    space_templates: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 10,
        search: "",
        count: 0,
        offset: 0,
    },
};

const ReducerSlice = createSlice({
    name: "space_templates",
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setSpaceTemplates(state, action) {
            if (action.payload.space_templates) {
                state.space_templates.rows = action.payload.space_templates;
            }
            if (
                action.payload.space_templates_aggregate &&
                action.payload.space_templates_aggregate.aggregate
            ) {
                state.space_templates.count = action.payload.space_templates_aggregate.aggregate.totalCount;
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.space_templates.offset = action.payload.offset;
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.space_templates.limit = action.payload.limit;
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.space_templates.search = action.payload.search;
            }
        },
        setSpaceTemplateMainError(state, action) {
            state.spaceTemplateMainInput = action.payload;
        }
    },
});

export const {
    setLoading,
    setSpaceTemplates,
    setSpaceTemplateMainError
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
