import { FormattedMessage } from 'react-intl'
import { IconNotes } from '@tabler/icons'
import { NavItemType } from 'src/types'
import { PageIcon, ListingIcon, NewsletterIcon, FormIcon, AnalyticIcon, EventIcon, CRMIcon, SpaceSettingIcon, DashboardIcon, SpaceIcon, UserIcon, OrganizationIcon, ExclusiveIcon, PartnersIcon, CommunicationIcon } from 'src/assets/icons'
import { GeneralIcon, StylingIcon, NavigationIcon, TemplateIcon, AssetIcon, TagIcon, SEOIcon, NewsletterSettingIcon, IntegrationIcon, MembershipIcon } from 'src/assets/icons'

const icons = {
    IconNotes
}

const other: { admin: NavItemType[], space: NavItemType[], config: NavItemType[] } = {
    admin: [
        {
            id: 'dashboard',
            type: 'group',
            children: [
                {
                    id: 'dashboard',
                    title: <FormattedMessage id='dashboard' />,
                    type: 'item',
                    url: '/dashboard',
                    icon: DashboardIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'space-management',
            type: 'group',
            title: <FormattedMessage id='space-management' />,
            children: [
                {
                    id: 'spaces',
                    title: <FormattedMessage id='spaces' />,
                    type: 'item',
                    url: '/space/list',
                    icon: SpaceIcon,
                    breadcrumbs: false
                },
                {
                    id: 'users',
                    title: <FormattedMessage id='users' />,
                    type: 'item',
                    url: '/user/list',
                    icon: UserIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'superadmin',
            title: <FormattedMessage id='superadmin' />,
            type: 'group',
            children: [
                {
                    id: 'space-list',
                    title: <FormattedMessage id='space-list' />,
                    type: 'item',
                    url: '/superadmin/spaces/list',
                    icon: icons.IconNotes,
                    breadcrumbs: false
                },
                {
                    id: 'space-template',
                    title: <FormattedMessage id='space-template' />,
                    type: 'item',
                    url: '/superadmin/space-template/list',
                    icon: icons.IconNotes,
                    breadcrumbs: false
                },
                {
                    id: 'blocks',
                    title: <FormattedMessage id='blocks' />,
                    type: 'item',
                    url: '/superadmin/blocks',
                    icon: icons.IconNotes,
                    breadcrumbs: false
                },
                {
                    id: 'blocks-data',
                    title: <FormattedMessage id='blocks-data' />,
                    type: 'item',
                    url: '/superadmin/blocks-data/list',
                    icon: icons.IconNotes,
                    breadcrumbs: false
                },
                {
                    id: 'customer',
                    title: <FormattedMessage id='customer' />,
                    type: 'item',
                    url: '/superadmin/customer/list',
                    icon: icons.IconNotes,
                    breadcrumbs: false
                },

            ]
        },
        {
            id: 'billing',
            type: 'group',
            title: <FormattedMessage id='billing' />,
            children: [
                {
                    id: 'organization',
                    title: <FormattedMessage id='organization' />,
                    type: 'item',
                    url: '/organization',
                    icon: OrganizationIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'blocks',
            title: <FormattedMessage id='block-management' />,
            type: 'group',
            children: [
                {
                    id: 'exclusive-blocks',
                    title: <FormattedMessage id='exclusive-blocks' />,
                    type: 'item',
                    url: '/exclusive-blocks',
                    icon: ExclusiveIcon,
                    breadcrumbs: false
                },
            ]
        },
    ],
    space: [
        {
            id: 'content-creation',
            type: 'group',
            children: [
                {
                    id: 'pages',
                    title: <FormattedMessage id='pages' />,
                    type: 'item',
                    url: '/page/list',
                    icon: PageIcon,
                    breadcrumbs: false
                },
                {
                    id: 'listings',
                    title: <FormattedMessage id='listings' />,
                    type: 'item',
                    url: '/listing/list',
                    icon: ListingIcon,
                    breadcrumbs: false
                },
                {
                    id: 'newsletter',
                    title: <FormattedMessage id='newsletter' />,
                    type: 'item',
                    url: '/newsletter/list',
                    icon: NewsletterIcon,
                    breadcrumbs: false
                },
                {
                    id: 'forms',
                    title: <FormattedMessage id='forms' />,
                    type: 'item',
                    url: '/form/list',
                    icon: FormIcon,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'analytics',
            type: 'group',
            children: [
                {
                    id: 'analytics',
                    title: <FormattedMessage id='analytics' />,
                    type: 'item',
                    url: '/analytics',
                    icon: AnalyticIcon,
                    breadcrumbs: false
                },
                {
                    id: 'event-tracking',
                    title: <FormattedMessage id='event-tracking' />,
                    type: 'item',
                    url: '/clicks',
                    icon: EventIcon,
                    breadcrumbs: false
                },
            ]
        },
        {
            id: 'space-management',
            type: 'group',
            children: [
                {
                    id: 'crm',
                    title: <FormattedMessage id='crm' />,
                    type: 'item',
                    url: '/member/list',
                    icon: CRMIcon,
                    breadcrumbs: false
                },
                {
                    id: 'partners',
                    title: <FormattedMessage id='partners' />,
                    type: 'item',
                    url: '/partner/list',
                    icon: PartnersIcon,
                    breadcrumbs: false
                },
                {
                    id: 'space-settings',
                    title: <FormattedMessage id='space-settings' />,
                    type: 'item',
                    url: '/config/general',
                    icon: SpaceSettingIcon,
                    breadcrumbs: false
                }
            ]
        }
    ],
    config: [
        {
            id: 'space-settings',
            type: 'group',
            children: [
                {
                    id: 'general',
                    title: <FormattedMessage id='general' />,
                    type: 'item',
                    url: '/config/general',
                    icon: GeneralIcon,
                    breadcrumbs: false
                },
                {
                    id: 'styling',
                    title: <FormattedMessage id='styling' />,
                    type: 'item',
                    url: '/config/styling',
                    icon: StylingIcon,
                    breadcrumbs: false
                },
                {
                    id: 'navigation',
                    title: <FormattedMessage id='navigation' />,
                    type: 'item',
                    url: '/config/navigation/list',
                    breadcrumbs: false,
                    icon: NavigationIcon,
                },
                {
                    id: 'communication',
                    title: <FormattedMessage id='communication' />,
                    type: 'item',
                    url: '/config/communication/list',
                    breadcrumbs: false,
                    icon: CommunicationIcon,
                },
                {
                    id: 'templates',
                    title: <FormattedMessage id='templates' />,
                    type: 'item',
                    url: '/config/template/list',
                    icon: TemplateIcon,
                    breadcrumbs: false
                },
                {
                    id: 'assets',
                    title: <FormattedMessage id='assets' />,
                    type: 'item',
                    url: '/config/assets',
                    icon: AssetIcon,
                    breadcrumbs: false
                },
                {
                    id: 'tags',
                    title: <FormattedMessage id='tags' />,
                    type: 'item',
                    url: '/config/tag/list',
                    icon: TagIcon,
                    breadcrumbs: false
                },
                {
                    id: 'seo',
                    title: <FormattedMessage id='seo' />,
                    type: 'item',
                    url: '/config/seo',
                    icon: SEOIcon,
                    breadcrumbs: false
                },
                {
                    id: 'newsletter',
                    title: <FormattedMessage id='newsletter' />,
                    type: 'item',
                    url: '/config/newsletter',
                    icon: NewsletterSettingIcon,
                    breadcrumbs: false
                },
                {
                    id: 'membership',
                    title: <FormattedMessage id='membership' />,
                    type: 'item',
                    url: '/config/membership',
                    icon: MembershipIcon,
                    breadcrumbs: false
                },
                {
                    id: 'integrations',
                    title: <FormattedMessage id='integrations' />,
                    type: 'item',
                    url: '/config/integrations',
                    icon: IntegrationIcon,
                    breadcrumbs: false
                }
            ]
        }
    ]
}

export default other
