// action - state management
import { createSlice } from "@reduxjs/toolkit";
// import { required } from "src/utils/validation";
// import { cloneDeep } from "src/utils/helper";
// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState: any = {
  loading: false,
  data: {
    list: [],
    filters: {
      order: "asc",
      orderBy: "",
      limit: 50,
      search: "",
      count: 0,
      offset: 0,
      is_deleted: false,
    },
  },
  datasetToDelete: null,
};

const ReducerSlice = createSlice({
  name: "dataset",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload || false;
    },
    setDatasetList(state, action) {
      state.data.list = [...action.payload];
    },
    setDatasetFilters(state, action) {
      state.data.filters = { ...state.data.filters, ...action.payload };
    },
  },
});

export const {
  setLoading,
  setDatasetList,
  setDatasetFilters,
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
