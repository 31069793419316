import { createContext, useEffect, useReducer } from 'react'
import firebase from 'firebase/app'
import { useNavigate } from 'react-router-dom'
import { LOGIN, LOGOUT, RESET, INITIALIZE } from 'src/store/actions'
import accountReducer from 'src/store/accountReducer'
import { toast } from 'react-toastify'
import Loader from 'src/ui-component/Loader'
import type { Object } from '@fortunecms/utils'
import { initialLoginContextProps } from 'src/types'
import { FirebaseContextType } from 'src/types/auth'
import { userService, User_Status_Enum, Space_Status_Enum } from '@fortunecms/services'
import { LOCAL_SPACE_ID } from 'src/store/constant'
import { User_Role_Enum } from 'src/types/custom-generated'
import { FIREBASE_API_KEY, FIREBASE_APP_ID, FIREBASE_MEASUREMENT_ID, FIREBASE_AUTH_DOMAIN, FIREBASE_PROJECT_ID, FIREBASE_TENANT_ID, FIREBASE_STORAGE_BUCKET, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_OAUTH_CLIENT_ID } from '@fortunecms/env'
import 'firebase/auth'

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp({
        appId: FIREBASE_APP_ID,
        apiKey: FIREBASE_API_KEY,
        projectId: FIREBASE_PROJECT_ID,
        authDomain: FIREBASE_AUTH_DOMAIN,
        measurementId: FIREBASE_MEASUREMENT_ID,
        storageBucket: FIREBASE_STORAGE_BUCKET,
        messagingSenderId: FIREBASE_MESSAGING_SENDER_ID
    })
    firebase.auth().tenantId = FIREBASE_TENANT_ID
}

const initialState: initialLoginContextProps = {
    user: null,
    isLoggedIn: false,
    isInitialized: false
}

const FirebaseContext = createContext<FirebaseContextType | null>(null)

export const FirebaseProvider = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
    const navigate = useNavigate()
    const [state, dispatch] = useReducer(accountReducer, initialState)
    const isValidJSON = (str: string) => { try { return (JSON.parse(str) && !!str) } catch (_) { return false } }
    const signOut = async (dispatchEvent = true) => {
        await firebase.auth().signOut()
        window.localStorage.removeItem(LOCAL_SPACE_ID)
        dispatchEvent && dispatch({ type: LOGOUT })
    }

    const onAuthStateChanged = async (user: firebase.User | null = firebase.auth().currentUser) => {
        dispatch({ type: RESET })
        console.log('user =', user)
        if (!user || !user.emailVerified) return signOut()
        const databaseUser: any = await userService.getUserByAttr({ uid: user.uid })
        console.log('databaseUser =', databaseUser)
        if (databaseUser) {
            if (databaseUser.status === User_Status_Enum.Disabled) {
                toast.error('You account has been disabled by Admin', { position: 'top-right' })
                return signOut()
            }
            if (databaseUser.status === User_Status_Enum.Unverified) {
                toast.error('Your account is currently on the waitlist. We will contact you when it is activated.', { position: 'top-right' })
                return signOut()
            }
            databaseUser.personalSpaces = databaseUser.spaces.filter(({ user_role, status }: { user_role: string, status: string }) => (user_role === User_Role_Enum.SpaceOwner || user_role === User_Role_Enum.Admin) && status !== Space_Status_Enum.Deleted)
            databaseUser.contributedSpaces = databaseUser.spaces.filter(({ user_role, status }: { user_role: string, status: string }) => (user_role !== User_Role_Enum.SpaceOwner && user_role !== User_Role_Enum.Admin) && status !== Space_Status_Enum.Deleted)
            delete databaseUser.spaces
            if (user.displayName !== databaseUser.name) await updateProfile({ displayName: databaseUser.name, resetAuth: false })
            if (databaseUser.info.profile && user.photoURL !== databaseUser.info.profile.photo_url) await updateProfile({ photoURL: databaseUser.info.profile.photo_url, resetAuth: false })
            dispatch({ type: LOGIN, payload: { user: databaseUser } })
        } else {
            navigate('/create-account')
            toast.error('Please register first.', { position: 'top-right' })
            return signOut()
        }
        dispatch({ type: INITIALIZE })
    }

    const refreshMeta = async (user: firebase.User | null = firebase.auth().currentUser) => {
        console.log('user =', user)
        if (!user) return
        const databaseUser: any = await userService.getUserByAttr({ uid: user.uid })
        if (!databaseUser) return
        databaseUser.personalSpaces = databaseUser.spaces.filter(({ user_role, status }: { user_role: string, status: string }) => (user_role === User_Role_Enum.SpaceOwner || user_role === User_Role_Enum.Admin) && status !== Space_Status_Enum.Deleted)
        databaseUser.contributedSpaces = databaseUser.spaces.filter(({ user_role, status }: { user_role: string, status: string }) => (user_role !== User_Role_Enum.SpaceOwner && user_role !== User_Role_Enum.Admin) && status !== Space_Status_Enum.Deleted)
        delete databaseUser.spaces
        dispatch({ type: LOGIN, payload: { user: databaseUser } })
    }

    const handleRedirection = async () => {
        if (!firebase.auth().isSignInWithEmailLink(window.location.href)) return
        if (!isValidJSON(window.atob(String(new URLSearchParams(window.location.search).get('ui'))))) return
        const { e } = JSON.parse(window.atob(String(new URLSearchParams(window.location.search).get('ui'))))
        await signOut(false)
        const { user } = await firebase.auth().signInWithEmailLink(e, window.location.href)
        if (!user || !user.metadata.lastSignInTime) return
        await userService.updateUserByAttr({ uid: user.uid, email: user.email || '', input: { last_login_at: new Date(), uid: user.uid } })
    }

    const loadGoogleIdentityServices = () => {
        return new Promise((resolve, reject) => {
            if (document.querySelector(`script[id='google-identity-services']`)) return resolve(true) // Check if the script is already loaded
            const script = document.createElement('script')
            script.async = true
            script.defer = true
            script.id = 'google-identity-services'
            script.src = 'https://accounts.google.com/gsi/client'
            script.onload = () => resolve(true)
            script.onerror = (error) => reject(new Error(`Failed to load the Google Identity Services library: ${(error as Object).message}`))
            document.body.appendChild(script)
        })
    }

    const loadGoogleIdentityPopup = async () => {
        if (state.isLoggedIn) return
        await loadGoogleIdentityServices()
        const method = (window as Object).google.accounts.id
        method.initialize({
            client_id: FIREBASE_OAUTH_CLIENT_ID,
            callback: async (response: Object) => {
                console.log('Encoded JWT ID token:', response.credential)
                const credential = firebase.auth.GoogleAuthProvider.credential(response.credential)
                try {
                    const userCredential = await firebase.auth().signInWithCredential(credential)
                    console.log('Signed in user:', userCredential.user)
                } catch (error) {
                    console.error('Error signing in with Google credential:', error)
                }
            }
        })
        document.cookie = 'g_state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/' // Remove the 'g_state' cookie
        method.prompt() // Display the One Tap prompt
    }

    useEffect(() => {
        handleRedirection().then(() => {
            firebase.auth().onAuthStateChanged(onAuthStateChanged)
        }).catch((err) => {
            signOut()
            toast.error(err.message, { position: 'top-right' })
        })
    }, [])

    const sendSignInLinkToEmail = (email: string) => {
        const userInfo: string = window.btoa(JSON.stringify({ e: email }))
        const continueUrl: string = `${window.location.origin}?${(new URLSearchParams({ ui: userInfo }))}`
        return firebase.auth().sendSignInLinkToEmail(email, { url: continueUrl, handleCodeInApp: true })
    }

    const isSignInWithEmailLink = (link: string) => firebase.auth().isSignInWithEmailLink(link)
    const signInWithEmailLink = (email: string, link: string) => firebase.auth().signInWithEmailLink(email, link)
    const firebaseGoogleSignIn = async () => {
        const provider = new firebase.auth.GoogleAuthProvider()
        const data = await firebase.auth().signInWithPopup(provider)
        if (data && data.user) await userService.updateUserByAttr({ uid: data.user.uid, email: data.user.email || '', input: { last_login_at: new Date(), uid: data.user.uid } })
        return data
    }

    const updateProfile = async ({ displayName, photoURL, resetAuth }: { displayName?: string, photoURL?: string, resetAuth?: boolean }) => {
        const user = firebase.auth().currentUser
        if (!user) return
        await user.updateProfile({ displayName, photoURL })
        if (resetAuth !== false) onAuthStateChanged()
    }

    const firebaseEmailPasswordSignIn = (email: string, password: string) => firebase.auth().signInWithEmailAndPassword(email, password)
    const firebaseRegister = (email: string, password: string) => firebase.auth().createUserWithEmailAndPassword(email, password)
    const resetPassword = async (email: string) => await firebase.auth().sendPasswordResetEmail(email)

    const sendFirebaseEmailVerification = async () => {
        await firebase.auth().currentUser?.sendEmailVerification()
        toast.info('Verification link sent to your email. Kinldy check to verify your account')
    }

    const resendFirebaseEmailVerification = async () => {
        await firebase.auth().currentUser?.sendEmailVerification()
        toast.info('Verification link sent to your email. Kinldy check to verify your account')
    }

    if (!state.isInitialized) return <Loader />
    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => null,
                logout: signOut,
                firebaseGoogleSignIn,
                loadGoogleIdentityPopup,
                resetPassword,
                updateProfile,
                sendFirebaseEmailVerification,
                resendFirebaseEmailVerification,
                sendSignInLinkToEmail,
                isSignInWithEmailLink,
                signInWithEmailLink,
                refreshMeta
            }}
        >
            {children}
        </FirebaseContext.Provider>
    )
}
export default FirebaseContext
