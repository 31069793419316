export function checkError(errors: any) {
  let validated = true;
  for (let val in errors) {
    if (errors[val] !== "") {
      validated = false;
    }
  }
  return validated;
}

export const required = (value: string) => {
  if (!value) {
    return "Required.";
  } else if (!value.toString().trim().length) {
    // We can return string or jsx as the 'error' prop for the validated Component
    return "Required.";
  }
  return "";
};

export const ValidateEmail = (mail: string) =>  {
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
    return "";
  }
    return "You have entered an invalid email address!";
}
