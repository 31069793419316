// action - state management
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "src/utils/helper";
// ==============================|| ACCOUNT REDUCER ||============================== //

const exclusiveMainInput = {
    name: "",
    type: "",
    meta: {}
};

const initialState = {
    loading: false,
    exclusiveMainInput: cloneDeep(exclusiveMainInput),
    exclusive: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 50,
        search: "",
        count: 0,
        offset: 0,
        is_deleted: false,
    },
};

const ReducerSlice = createSlice({
    name: "exclusive",
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setExclusive(state, action) {
            if (action.payload.exclusive) {
                state.exclusive.rows = action.payload.exclusive;
            }
            if (
                action.payload.exclusive_aggregate &&
                action.payload.exclusive_aggregate.aggregate
            ) {
                state.exclusive.count =
                    action.payload.exclusive_aggregate.aggregate.totalCount;
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.exclusive.offset = action.payload.offset;
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.exclusive.limit = action.payload.limit;
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.exclusive.search = action.payload.search;
            }
            if (
                action.payload.is_deleted !== undefined &&
                action.payload.is_deleted !== null
            ) {
                state.exclusive.is_deleted = action.payload.is_deleted;
            }
        },
        setExclusiveMainInput(state: any, action) {
            state.exclusiveMainInput[action.payload.key] = action.payload.value || "";
        },
        setExclusiveMetaInput(state: any, action) {
            state.exclusiveMainInput.meta[action.payload.key] = action.payload.value || "";
        },
        resetExclusiveMain(state) {
            state.exclusiveMainInput = cloneDeep(exclusiveMainInput);
        },
        setExclusiveMain(state: any, action) {
            for (let key in action.payload) {
                if (key !== "__typename") {
                    state.exclusiveMainInput[key] = action.payload[key];
                }
            }
        }
    }
});

export const {
    setLoading,
    setExclusive,
    resetExclusiveMain,
    setExclusiveMainInput,
    setExclusiveMain,
    setExclusiveMetaInput
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
