import { useSelector } from 'react-redux'
import { ThemeProvider, Theme } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import Routes from 'src/routes'
import { DefaultRootStateProps } from 'src/types'
import themes from 'src/themes'
import Locales from 'src/ui-component/Locales'
import NavigationScroll from 'src/layout/NavigationScroll'
import Snackbar from 'src/ui-component/extended/Snackbar'
import { FirebaseProvider } from 'src/contexts/FirebaseContext'
import { SpaceProvider } from 'src/contexts/SpaceContext'
import { LoadingProvider } from './contexts/LoadingContext'
import { ConfigContextProvider } from 'src/contexts/ConfigContext'

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                {/* RTL layout */}
                {/* <RTLLayout> */}
                <Locales>
                    <NavigationScroll>
                        <FirebaseProvider>
                            <SpaceProvider>
                                <ConfigContextProvider>
                                    <LoadingProvider>
                                        <Routes />
                                        <Snackbar />
                                    </LoadingProvider>
                                </ConfigContextProvider>
                            </SpaceProvider>
                        </FirebaseProvider>
                    </NavigationScroll>
                </Locales>
                {/* </RTLLayout> */}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
