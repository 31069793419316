import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// import 'src/_mockApis'

// project imports
import * as serviceWorker from 'src/serviceWorker'
import App from 'src/App'
import { store, persister } from 'src/store'

// style + assets
import './assets/scss/style.scss'
import config from 'src/config'

// aws config
// import AWS from 'aws-sdk'

// import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, AWS_REGION } from '@fortunecms/env'

// AWS.config.update({
//     region: AWS_REGION,
//     accessKeyId: AWS_ACCESS_KEY_ID,
//     secretAccessKey: AWS_SECRET_ACCESS_KEY
// })

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.querySelector('#root')
const root = createRoot(container!)

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
            <BrowserRouter basename={config.basename}>
                <ToastContainer autoClose={2500} style={{ zIndex: 999999999 }} position='bottom-left' />
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
