// action - state management
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import { required } from "src/utils/validation";

// ==============================|| ACCOUNT REDUCER ||============================== //

const pageMainInput = {
  name: "",
  slug: "",
  title: "",
  tag_ids: [],
  meta_description: "",
  head_injection: "",
  published_at : undefined,
  space_id: '',
  thumbnail : ""
}
const initialState: any = {
  loading: false,
  pageMainInput: cloneDeep(pageMainInput),
  pageMainInputError: {},
  pages: {
    rows: [],
    order: "asc",
    orderBy: "",
    limit: 50,
    search: "",
    count: 0,
    offset: 0,
    is_deleted: false,
  },
  blocks: [],	
  blockIdsToDelete:[],
  pageToDelete: null,
  blockToDelete: null,	
  pageTabIndex: 0
};

const ReducerSlice = createSlice({
  name: "page",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload || false;
    },
    setPageTabIndex(state, action) {
        state.pageTabIndex = action.payload || 0;
      },
    setPageMainInput(state: any, action) {
      console.log("options action.payload : ", action.payload);
      state.pageMainInput[action.payload.key] = action.payload.value || "";

      
      if (action.payload.required) {
        state.pageMainInputError[action.payload.key] = required(
          action.payload.value
        );
      }
      if(action.payload.key == "slug" && !state.pageMainInputError[action.payload.key])
      {

        if (/^[A-Za-z0-9]+(?:(-|\/)[A-Za-z0-9]+)*$/.test(action.payload.value))
        {
            state.pageMainInputError[action.payload.key]  = '';
        }
        else
        {
            state.pageMainInputError[action.payload.key]  = "Please enter valid slug. Exa. hello, hello-one"
        }      
      }
      
    },
    setPageMainError(state, action) {
      state.pageMainInputError = action.payload;
    },
    setPages(state, action) {
      if (action.payload.page) {
        state.pages.rows = action.payload.page.map((x: any, index : number) => {

               let y = {...x};
               if(index > 0 && action.payload.page[index-1].slug === x.slug)
               {

                   y.hasDuplicate = true; 
               } 
               return y;
        });
      }
      if (
        action.payload.page_aggregate &&
        action.payload.page_aggregate.aggregate
      ) {
        state.pages.count = action.payload.page_aggregate.aggregate.totalCount;
      }
      if (
        action.payload.offset !== undefined &&
        action.payload.offset !== null
      ) {
        state.pages.offset = action.payload.offset;
      }
      if (action.payload.limit !== undefined && action.payload.limit !== null) {
        state.pages.limit = action.payload.limit;
      }
      if (
        action.payload.search !== undefined &&
        action.payload.search !== null
      ) {
        state.pages.search = action.payload.search;
      }
      if (
        action.payload.is_deleted !== undefined &&
        action.payload.is_deleted !== null
      ) {
        state.pages.is_deleted = action.payload.is_deleted;
      }
    },
    setPageToDelete(state, action) {
      state.pageToDelete = action.payload;
    },
    deletePage(state) {
      state.pages.rows = state.pages.rows.filter(
        (page: any) => page.id !== state.pageToDelete.id
      );
    },
    clearDeletedBlockIds(state)
    {
        state.blockIdsToDelete = [];
    }
  },
});

export const {
  setPageMainInput,
  setPageMainError,
  setLoading,
  setPages,
  setPageToDelete,
  deletePage,
  clearDeletedBlockIds,
  setPageTabIndex
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
