// action - state management
import { LOGIN, LOGOUT, RESET, INITIALIZE } from './actions'
import { initialLoginContextProps } from 'src/types'

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
}

export interface AccountReducerActionProps {
    type: string;
    payload?: initialLoginContextProps;
}

const accountReducer = (state = initialState, action: AccountReducerActionProps) => {
    switch (action.type) {
        case INITIALIZE: {
            return {
                ...state,
                isInitialized: true
            }
        }
        case LOGIN: {
            const { user } = action.payload!
            return {
                ...state,
                isLoggedIn: true,
                user
            };
        }
        case RESET: {
            return {
                ...initialState,
                isInitialized: false
            }
        }
        case LOGOUT: {
            return {
                ...initialState,
                isInitialized: true
            }
        }
        default: {
            return { ...state }
        }
    }
}

export default accountReducer
