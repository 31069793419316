import React, { createContext, useContext, useState, ReactNode } from 'react'
import useSpace from 'src/hooks/useSpace'
import { CONFIG } from 'src/store/constant'

type ConfigContextType = {
    config: string | null
    setConfig: (value: string | null) => void
    setConfigOverride: () => void
}

const ConfigContext = createContext<ConfigContextType | undefined>(undefined)

export const useConfig = (): ConfigContextType => {
    const context = useContext(ConfigContext)
    if (!context) {
        throw new Error('useConfig must be used within a ConfigContextProvider')
    }
    return context
}

type ConfigContextProviderProps = {
    children: ReactNode
}

export const ConfigContextProvider: React.FC<ConfigContextProviderProps> = ({ children }) => {
    const [config, setConfig] = useState<string | null>(null)
    const [space] = useSpace()

    function setConfigOverride() {
        if (!space?.id) {
            window.localStorage.removeItem(CONFIG)
            setConfig(null)
        } else {
            window.localStorage.setItem(CONFIG, 'Active')
            setConfig('Active')
        }
    }

    return (
        <ConfigContext.Provider value={{ config, setConfig, setConfigOverride }}>
            {children}
        </ConfigContext.Provider>
    )
}

export default ConfigContext
