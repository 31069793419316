// action - state management
import { createSlice } from "@reduxjs/toolkit";


const initialState: any = {
  loading: false,
  listings: {
    rows: [],
    order: "asc",
    orderBy: "",
    limit: 50,
    search: "",
    count: 0,
    is_deleted: false,
    offset: 0
  },
  viewListingPreview: false
};

const ReducerSlice = createSlice({
  name: "listing",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload || false;
    },
    
    setListings(state, action) {
      if (action.payload.listing) {
        state.listings.rows = action.payload.listing;
      }
      if (
        action.payload.listing_aggregate &&
        action.payload.listing_aggregate.aggregate
      ) {
        state.listings.count = action.payload.listing_aggregate.aggregate.totalCount;
      }
      if (
        action.payload.offset !== undefined &&
        action.payload.offset !== null
      ) {
        state.listings.offset = action.payload.offset;
      }
      if (action.payload.limit !== undefined && action.payload.limit !== null) {
        state.listings.limit = action.payload.limit;
      }
      if (
        action.payload.search !== undefined &&
        action.payload.search !== null
      ) {
        state.listings.search = action.payload.search;
      }

    },
    setListingPreview(state, action) {
      state.viewListingPreview = action.payload;
    },
    deleteListing(state, action) {
      state.listingToDelete = action.payload;
    }
  },
});

export const {
  setLoading,
  setListings,
  setListingPreview,
  deleteListing
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
