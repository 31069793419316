import { useEffect } from 'react'
import config from 'src/config'
import firebase from 'firebase/app'
import { GuardProps } from 'src/types'
import useAuth from 'src/hooks/useAuth'
import useSpace from 'src/hooks/useSpace'
// import { LoginRoutes } from 'src/routes/AuthenticationRoutes'
import { useNavigate, matchRoutes, RouteObject } from 'react-router-dom'
import { AdminRoutes, SpaceRoutes, CommonRoutes, LoginRoutes } from 'src/routes/MainRoutes'

type RouteType = 'admin' | 'space' | 'common' | 'login'

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children = <></> }: GuardProps) => {
    const [space] = useSpace()
    const navigate = useNavigate()
    const { isLoggedIn } = useAuth()
    const { currentUser } = firebase.auth()

    // const matchRoute = (path: string) => new RegExp('^' + path.split('/').map(p => p.startsWith(':') ? '..*' : p).join('/') + '$').test(window.location.pathname)

    const matchRoute = (routes: RouteObject[]) => !!matchRoutes(routes, window.location)

    function doesRouteExist(routeName: RouteType | RouteType[]): boolean {
        const routes = { admin: AdminRoutes, space: SpaceRoutes, common: CommonRoutes, login: LoginRoutes }
        if (!Array.isArray(routeName)) return matchRoute(routes[routeName])
        return routeName.some(route => matchRoute(routes[route]))
        // if (routeName === 'admin' && matchRoutes(AdminRoutes, window.location)) return true
        // else if (routeName === 'space' && matchRoutes(SpaceRoutes, window.location)) return true
        // else if (routeName === 'common' && matchRoutes(CommonRoutes, window.location)) return true
        // else if (routeName === 'login' && matchRoutes(LoginRoutes, window.location)) return true
        // return false
    }

    useEffect(() => {
        console.log('space =', space)
        console.log('isLoggedIn =', isLoggedIn)
        if (!isLoggedIn) {
            if (!doesRouteExist('login')) navigate(config.defaultPath, { replace: true })
            else if (currentUser && currentUser.emailVerified) navigate('/create-account')
        } else {
            if (!space && !doesRouteExist(['admin', 'common'])) navigate('/dashboard', { replace: true })
            else if (space && !doesRouteExist(['space', 'common'])) navigate('/page/list', { replace: true })
            else if (doesRouteExist('login') && currentUser) navigate('/dashboard', { replace: true })
            // else navigate('/dashboard', { replace: true })
        }
    }, [isLoggedIn, currentUser, space])

    return children
}

export default AuthGuard
