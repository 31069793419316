// action - state management
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "src/utils/helper";
import { required } from "src/utils/validation";

const memberMainInput = {
    email: "",
    role: null,
    info: {
        first_name: '',
        last_name: '',
        status: "DISABLED"
    }
};
const initialState: any = {
    loading: false,
    memberMainInput: cloneDeep(memberMainInput),
    memberMainInputError: {},
    member: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 50,
        search: "",
        count: 0,
        offset: 0,
        is_deleted: false,
    },
    memberToDelete: null,
};

const ReducerSlice = createSlice({
    name: "member",
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setMemberMainInput(state: any, action) {
            console.log("options action.payload : " + action.payload);
            if(action.payload.isInfo) {
                state.memberMainInput.info[action.payload.key] = action.payload.value || "";
            } else state.memberMainInput[action.payload.key] = action.payload.value || "";
            if (action.payload.required) {
                state.memberMainInputError[action.payload.key] = required(
                    action.payload.value
                );
            }
        },
        resetMemberMain(state) {
            state.memberMainInput = cloneDeep(memberMainInput);
        },
        setMemberMain(state: any, action) {
            for (let key in action.payload) {
              if (key !== "__typename" && key !== "relations") {
                state.memberMainInput[key] = action.payload[key];
              }
            }
          },
        setMembers(state, action) {
            if (action.payload.member) {
                state.member.rows = action.payload.member;
            }
            if (action.payload.space) {
                state.member.space = action.payload.space;
            }
            if (
                action.payload.member_aggregate &&
                action.payload.member_aggregate.aggregate
            ) {
                state.member.count = action.payload.member_aggregate.aggregate.totalCount;
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.member.offset = action.payload.offset;
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.member.limit = action.payload.limit;
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.member.search = action.payload.search;
            }
            if (
                action.payload.is_deleted !== undefined &&
                action.payload.is_deleted !== null
            ) {
                state.member.is_deleted = action.payload.is_deleted;
            }
        },
        setMemberToDelete(state, action) {
            state.memberToDelete = action.payload;
        },
        setMemberMainError(state, action) {
            state.memberMainInputError = action.payload;
          },
        deleteMember(state) {
            state.member.rows = state.member.rows.filter(
                (member: any) => member.id !== state.memberToDelete.id
            );
        }
    },
});

export const {
    setLoading,
    setMembers,
    setMemberToDelete,
    deleteMember,
    setMemberMainInput,
    resetMemberMain,
    setMemberMain,
    setMemberMainError
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
