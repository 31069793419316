// @ts-nocheck
import { ForwardRefExoticComponent, RefAttributes, forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from 'src/store/actions';
import { DefaultRootStateProps, LinkTarget, NavItemType } from 'src/types';
import { styled } from '@mui/material/styles'
import MuiTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export interface NavItemProps {
    item: NavItemType;
    level: number;
}

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: NavItemProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state: DefaultRootStateProps) => state.customization);
    const drawerOpen: boolean = useSelector((state: DefaultRootStateProps) => state.customization.opened)
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const Icon: any = item?.icon!;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.2} width={20} height={20} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url!} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id: string) => {
        dispatch({ type: MENU_OPEN, id });
        matchesSM && dispatch({ type: SET_MENU, opened: false });
    };

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => <MuiTooltip {...props} classes={{ popper: className }} />)(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                fontSize: 11,
                boxShadow: theme.shadows[1],
                color: 'rgba(0, 0, 0, 0.87)',
                backgroundColor: theme.palette.common.white
            }
        })
    )

    // active menu item on page load
    useEffect(() => {
        const current = window.location.pathname === item.url
        if (current) dispatch({ type: MENU_OPEN, id: item.id })
        // eslint-disable-next-line
    }, [])

    if (!drawerOpen) {
        return (
            <LightTooltip placement='right' title={item.title}>
                <Box {...listItemProps} sx={{
                    marginY: 0.6,
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #eaeaea',
                    backgroundColor: customization.isOpen.findIndex((id) => id === item.id) > -1 ? '#f1f1f1' : 'transparent',
                    '&:hover': {
                        backgroundColor: '#f1f1f1'
                    }
                }}
                    onClick={() => itemHandler(item.id!)}
                >
                    <Icon height={24} />
                </Box>
            </LightTooltip>
        )
    }
    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: '5px',
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                cursor: 'pointer',
                // py: '4px',
                // pl: `${level * 24}px`,
                margin: "0px 0px 2px",
                padding: level === 1 ? `6px 12px 6px 12px` : `6px 12px 6px 40px`,
                color: customization.isOpen.findIndex((id) => id === item.id) > -1 ? '#1F2047' : '#484848',
                fontWeight: customization.isOpen.findIndex((id) => id === item.id) > -1 ? 500 : 400,
                '&:hover': {
                    backgroundColor: '#eee',
                    color: '#1F2047',
                    fontWeight: 400
                }
            }}
            selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={() => itemHandler(item.id!)}
        >
            <ListItemIcon sx={{
                my: 'auto', minWidth: !item?.icon ? 18 : 36, '&:hover': {
                    color: '#1F2047',
                }
            }}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;
