import { Suspense } from 'react';

// project imports
import Loader, { LoaderProps } from './Loader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

// type =>>> Component: LazyExoticComponent<() => JSX.Element> | ComponentType<React.ReactNode>
const Loadable = (Component: any) => (props: LoaderProps) => (
    <Suspense fallback={<Loader />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;
