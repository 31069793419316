// action - state management
import { createSlice } from "@reduxjs/toolkit";
// import { required } from "src/utils/validation";
// import { cloneDeep } from "src/utils/helper";
// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState: any = {
    loading: false,
    timeParams: { from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(), to: new Date().toISOString(), period: '' },
    linkTimeParams: { from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(), to: new Date().toISOString(), period: '' },
    filterDialogType: "",
    linkFilterDialogType: "",
    linkFilter: [],
    open: false,
    linkOpen: false,
    filters: {
        page: []
    }
}

const ReducerSlice = createSlice({
    name: 'space',
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setError(state, action) {
            state.error = action.payload || null;
        },
        openFilterDialog(state, action) {
            state.open = true;
            state.filterDialogType = action.payload;
        },
        openLinkFilterDialog(state, action) {
            state.linkOpen = true;
            state.linkFilterDialogType = action.payload;
        },
        closeFilterDialog(state) {
            state.open = false;
            state.filterDialogType = "";
        },
        closeLinkFilterDialog(state) {
            state.linkOpen = false;
            state.linkFilterDialogType = "";
        },
        addLinkFilter(state, action) {
            const { type, value } = action.payload;
            state.linkFilter.push({ type, value });
        },
        removeLinkFilter(state, action) {
            const { type } = action.payload;
            const arr = state.linkFilter.filter((el: { type: any; }) => el.type != type);
            state.linkFilter = arr;
        },
        resetLinkFilter(state) {
            state.linkFilter = [];
        },
        applyFilter(state, action) {
            const { filters } = state;

            let currentIndex = filters[action.payload.key].map((x: any) => x.id).indexOf(action.payload.value.id)

            if (currentIndex == -1) {
                filters[action.payload.key].push(action.payload.value);
            }
        },
        removeFilter(state, action) {
            const { filters } = state;

            let currentIndex = filters[action.payload.key].map((x: any) => x.id).indexOf(action.payload.value.id)

            if (currentIndex > -1) {
                filters[action.payload.key].child.splice(currentIndex, 1);
            }
        },
        setTime(state: any, action) {
            for (let key in action.payload) {
                state.timeParams[key] = action.payload[key];
            }
        },
        setLinkTime(state: any, action) {
            for (let key in action.payload) {
                state.linkTimeParams[key] = action.payload[key];
            }
        },
    }
});
export const {
    setLoading,
    setError,
    openFilterDialog,
    closeFilterDialog,
    applyFilter,
    removeFilter,
    closeLinkFilterDialog,
    openLinkFilterDialog,
    addLinkFilter,
    removeLinkFilter,
    setTime,
    setLinkTime,
    resetLinkFilter
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
