// project imports
import { CustomTypography } from 'src/themes/types';

export default function componentStyleOverrides(theme: CustomTypography) {
    console.log('theme =', theme)
    // const bgColor = theme?.customization?.navType === 'dark' ? theme.colors?.darkBackground : theme.colors?.grey50;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    borderRadius: '5px'
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    margin: "0px 0px 2px",
                    padding: "4px 12px 4px 12px",
                    '&.Mui-selected': {
                        color: '#1F2047',
                        backgroundColor: '#eee',
                        '&:hover': {
                            backgroundColor: '#eee'
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: '#484848',
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: '#aaaaaa',
                        fontSize: '14px',
                        fontWeight: 500,
                        fontFamily: 'Inter',
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    borderRadius: '3px',
                    background: '#f2f2f2',
                    fontSize: '13px',
                    lineHeight: '20px',
                    height: '46px',
                    border: 'none',
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                        // borderColor: theme.colors?.primaryMain
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                        // borderColor: theme.colors?.primaryMain
                    },
                    // "& .css-ilodt5-MuiInputBase-input-MuiOutlinedInput-input.MuiInputBase-inputSizeSmall" : {
                    // padding: '6px 8px'
                    // },
                    // // '& $notchedOutline': { borderRadius: '50px' },
                    // '&:hover $notchedOutline': { borderColor: theme.colors?.secondaryMain },
                    // '&:focused $notchedOutline': { borderColor: theme.colors?.secondaryMain },
                    // '&:hover $notchedOutline': {
                    // borderColor: '#ffff00'
                    // },
                    // background: 'white',
                    // padding: '6px 8px',
                    // fontSize: '13px',
                    // fontWeight: 400,
                    // background: theme?.customization?.outlinedFilled ? bgColor : 'transparent',
                    // borderRadius: `${theme?.customization?.borderRadius}px`,
                    // '&:hover .MuiOutlinedInput-notchedOutline': {
                    //     borderColor: `${theme.colors?.secondaryMain} !important`
                    //     // borderColor: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 28 : theme.colors?.grey400
                    // },
                    // '&:hover fieldset': {
                    //     borderColor: `${theme.colors?.secondaryMain} !important`
                    // },
                    // '&.MuiInputBase-multiline': {
                    //     padding: 1
                    // }

                    // '&:hover fieldset': {
                    //     color: `${theme.colors?.secondaryMain} !important`,
                    //     borderColor: `${theme.colors?.secondaryMain} !important`
                    // },
                    // this will applied when hovered (input border color change)
                    // '&:hover fieldset': {
                    // },
                    // this will be applied when focused (input border color change)
                    // '&:focused fieldset': {
                    //     borderColor: `${theme.colors?.secondaryMain} !important`
                    // }
                },
                input: {
                    fontSize: '14px',
                    fontWeight: 500,
                    padding: '12px',
                    color: '#1f2047',
                    fontFamily: 'Inter',
                    lineHeight: '16px'
                    // fontWeight: 500,
                    // background: theme?.customization?.outlinedFilled ? bgColor : 'transparent',
                    // padding: '15.5px 14px',
                    // borderRadius: `${theme?.customization?.borderRadius}px`,
                    // '&.MuiInputBase-inputSizeSmall': {
                    //     padding: '10px 14px',
                    //     '&.MuiInputBase-inputAdornedStart': {
                    //         paddingLeft: 0
                    //     }
                    // }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: '3px',
                    border: 'none'
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 50 : theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.customization?.navType === 'dark' ? theme?.colors?.primaryMain : theme?.colors?.primaryLight
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        background:
                            theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 20 : theme.colors?.primaryLight,
                        borderRadius: 3,
                        color: theme.textDark,
                        '.MuiChip-deleteIcon': {
                            color:
                                theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 80 : theme.colors?.primary200
                        }
                    }
                },
                popper: {
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: theme?.customization?.navType === 'dark' ? 0.2 : 1
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    /** checked not prop
                     *"&.Mui-checked": {
                     *    fontSize: "28px"
                     *}
                     */
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors?.darkLevel1 : theme.colors?.primaryDark,
                    background: theme?.customization?.navType === 'dark' ? theme.darkTextPrimary : theme.colors?.primary200
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.textDark,
                    fontSize: '13px'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: theme?.customization?.navType === 'dark' ? theme.colors?.darkPaper : theme.colors?.primaryLight,
                    '& .MuiTabs-flexContainer': {
                        borderColor:
                            theme?.customization?.navType === 'dark' ? theme?.colors?.darkTextPrimary! + 20 : theme.colors?.primary200
                    },
                    '& .MuiTab-root': {
                        color: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextSecondary : theme.colors?.grey900
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.colors?.primaryDark
                    },
                    '& .Mui-selected': {
                        color: theme.colors?.primaryDark
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                flexContainer: {
                    borderBottom: '1px solid',
                    borderColor: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 20 : theme.colors?.grey200
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '12px 0 12px 0'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderColor: theme?.customization?.navType === 'dark' ? theme.colors?.darkTextPrimary! + 15 : theme.colors?.grey200,
                    '&:not(.MuiTableCell-head)': {
                        '&.MuiTableCell-root': { // padding to non header cells
                            padding: '7px 16px'
                        }
                    },
                    '&.MuiTableCell-head': {
                        fontSize: '13px',
                        color: theme.heading,
                        fontWeight: 500,
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme?.customization?.navType === 'dark' ? theme.colors?.darkLevel1 : theme.paper,
                    background: theme?.customization?.navType === 'dark' ? theme.colors?.grey50 : theme.colors?.grey700
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem'
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    color: '#FF0000',
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    paddingTop: '3px',
                    margin: 0
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    padding: '0px 6px'
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: '6px 12px',
                    margin: '0px 5px',
                    borderRadius: '3px',
                    '&.Mui-selected': {
                        backgroundColor: '#eee',
                        fontWeight: 600,
                        color: '#1F2047'
                    }
                }
            }
        }
    };
}
