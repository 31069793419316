import { createContext, useState, useEffect } from 'react'
import useAuth from 'src/hooks/useAuth'
import { LOCAL_SPACE_ID } from 'src/store/constant'

const SpaceContext = createContext<any>([])

const SpaceContextProvider = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
    const { user } = useAuth()
    const [space, setSpace] = useState<any>(user.personalSpaces.concat(user.contributedSpaces).find(({ id }: { id: string }) => id === window.localStorage.getItem(LOCAL_SPACE_ID)))

    function setSpaceOverride(spaceId: string) {
        if (!spaceId) window.localStorage.removeItem(LOCAL_SPACE_ID)
        else window.localStorage.setItem(LOCAL_SPACE_ID, spaceId)
        refreshSpace()
    }

    function refreshSpace() {
        const spaceId = window.localStorage.getItem(LOCAL_SPACE_ID)
        console.log('spaceId =', spaceId)
        if (!spaceId) return setSpace(null)
        const space = user.personalSpaces.concat(user.contributedSpaces).find(({ id }: { id: string }) => id === spaceId)
        if (!space) window.localStorage.removeItem(LOCAL_SPACE_ID)
        setSpace(space)
    }

    useEffect(() => {
        refreshSpace()
    }, [user])

    return <SpaceContext.Provider value={[space, setSpaceOverride, setSpace]}>{children}</SpaceContext.Provider>
}

export const SpaceProvider = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
    const { isLoggedIn } = useAuth()
    if (!isLoggedIn) return <div children={children} />
    return <SpaceContextProvider children={children} />
}

export default SpaceContext
