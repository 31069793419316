// action - state management
import { createSlice } from "@reduxjs/toolkit";

// ==============================|| ACCOUNT REDUCER ||============================== //
const initialState: any = {
    loading: false,
    spaces: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 50,
        search: "",
        count: 0,
        offset: 0,
    }
};

const ReducerSlice = createSlice({
    name: "spaces",
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setSpaces(state, action) {
            if (action.payload.spaces) {
                state.spaces.rows = action.payload.spaces;
            }
            if (
                action.payload.space_aggregate &&
                action.payload.space_aggregate.aggregate
            ) {
                state.spaces.count = action.payload.space_aggregate.aggregate.totalCount;
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.spaces.offset = action.payload.offset;
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.spaces.limit = action.payload.limit;
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.spaces.search = action.payload.search;
            }
        },
    },
});

export const {
    setLoading,
    setSpaces,
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
