// action - state management
import { createSlice } from "@reduxjs/toolkit";

// ==============================|| ACCOUNT REDUCER ||============================== //


const initialState: any = {
  loading: false,
  templates: {
    rows: [],
    order: "asc",
    orderBy: "",
    limit: 50,
    search: "",
    count: 0,
    offset: 0,
  },
  templateToDelete: null,
};

const ReducerSlice = createSlice({
  name: "template",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload || false;
    },
    setTemplates(state, action) {
      if (action.payload.template) {
        state.templates.rows = action.payload.template.map((x: any, index : number) => {

               let y = {...x};
               if(index > 0 && action.payload.template[index-1].slug === x.slug)
               {

                   y.hasDuplicate = true; 
               } 
               return y;
        });
      }
      if (
        action.payload.template_aggregate &&
        action.payload.template_aggregate.aggregate
      ) {
        state.templates.count = action.payload.template_aggregate.aggregate.totalCount;
      }
      if (
        action.payload.offset !== undefined &&
        action.payload.offset !== null
      ) {
        state.templates.offset = action.payload.offset;
      }
      if (action.payload.limit !== undefined && action.payload.limit !== null) {
        state.templates.limit = action.payload.limit;
      }
      if (
        action.payload.search !== undefined &&
        action.payload.search !== null
      ) {
        state.templates.search = action.payload.search;
      }
      if (
        action.payload.is_deleted !== undefined &&
        action.payload.is_deleted !== null
      ) {
        state.templates.is_deleted = action.payload.is_deleted;
      }
    },
    setTemplateToDelete(state, action) {
      state.templateToDelete = action.payload;
    },
    deleteTemplate(state) {
      state.templates.rows = state.templates.rows.filter(
        (template: any) => template.id !== state.templateToDelete.id
      );
    },

    clearDeletedBlockIds(state)
    {
        state.blockIdsToDelete = [];
    },
  },
});

export const {
      setLoading,
  setTemplates,
  setTemplateToDelete,
  deleteTemplate,
  clearDeletedBlockIds
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
