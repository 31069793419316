// action - state management
import { createSlice } from "@reduxjs/toolkit";
// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    loading: false,
    defaultPageHeader: null,
    defaultNewsletterHeader: null,
    isTypeModalOpen: false,        
    headers: {
        rows: [],
        order: "asc",
        orderBy: "",
        limit: 50,
        search: "",
        count: 0,
        offset: 0,
        is_deleted: false,
    },
    headerToDelete: null,
};

const ReducerSlice = createSlice({
    name: "header",
    initialState: initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload || false;
        },
        setTypeModal(state, action) {
            state.isTypeModalOpen = action.payload || false;
        },
        setDefaultPageHeader(state, action) {
            state.defaultPageHeader = action.payload || null;
        },
        setDefaultNewsletterHeader(state, action) {
            state.defaultNewsletterHeader = action.payload || null;
        },
        setHeaders(state, action) {
            if (action.payload.header) {
                state.headers.rows = action.payload.header;
            }
            if (
                action.payload.header_aggregate &&
                action.payload.header_aggregate.aggregate
            ) {
                state.headers.count =
                    action.payload.header_aggregate.aggregate.totalCount;
            }
            if (
                action.payload.offset !== undefined &&
                action.payload.offset !== null
            ) {
                state.headers.offset = action.payload.offset;
            }
            if (action.payload.limit !== undefined && action.payload.limit !== null) {
                state.headers.limit = action.payload.limit;
            }
            if (
                action.payload.search !== undefined &&
                action.payload.search !== null
            ) {
                state.headers.search = action.payload.search;
            }
            if (
                action.payload.is_deleted !== undefined &&
                action.payload.is_deleted !== null
            ) {
                state.headers.is_deleted = action.payload.is_deleted;
            }
        },
        deleteHeader(state, action) {
            state.headerToDelete = action.payload;
        }
    },
});

export const {
    setLoading,
    setHeaders,
    deleteHeader,
    setTypeModal,
    setDefaultPageHeader,
    setDefaultNewsletterHeader,
} = ReducerSlice.actions;
export default ReducerSlice.reducer;
